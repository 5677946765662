import { cipo } from "cipo";

cipo.controller("addAttachmentsNoteController", function ($scope, $mdDialog, entityInstanceId, fieldId, parentEntityInstanceId, onlyView, attachments) {
    $scope.fieldId = fieldId;
    $scope.entityInstanceId = entityInstanceId;
    $scope.parentEntityInstanceId = parentEntityInstanceId;
    $scope.onlyView = onlyView;
    $scope.attachments = attachments;

    $scope.onClose = function (attachments) {
      $mdDialog.hide(attachments);
    };
  }
);