import { Pipe, PipeTransform, inject } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { UserStore } from '../../signal-store/user.store';

@Pipe({ name: 'itvFileSize' })
export class ItvFileSizePipe implements PipeTransform {
  utilsService = inject(UtilsService);
  userStore = inject(UserStore);

  transform(value: number | string | null | undefined, decimals: number = 2): string {
    if (value === null || value === undefined || value.toLocaleString().trim() === '') {
      return '';
    }

    const userSettings = this.userStore.calculatedUserSettings();
    let newValue = value;
    const bytes: number = typeof newValue == 'string' ? Number((newValue as any)?.toString()) : newValue;

    if (bytes === 0) {
      return '0 Bytes';
    }

    var k = 1000,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      this.utilsService.formatLocaleNumber(
        bytes / Math.pow(k, i),
        decimals,
        userSettings?.decimalSeparator,
        userSettings?.thousandSeparator,
        true,
      ) +
      ' ' +
      sizes[i]
    );
  }
}
