import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { forkJoin, of } from 'rxjs';

import { NewDemoRoutes } from '../demo/demo.menu';
import { KeyValueModel } from '../models/common';
import { User, UserSettings } from '../models/core';
import { MenuModel, RouteCodeEnum } from '../models/menu';
import { Permission } from '../models/permission';
import { TenantSettingsEnum, TenantSettingValue } from '../models/tenant';
import { CIPO_THEME, CIPO_THEME_MODE, MENU_SHOW_KEY, MODULE } from '../shared/consts';
import { UserApiService } from '../shared/services';
import { ContextInfo } from '../system/context/context.model';
import { MenuItem } from '../system/menu/menu.component';
import { UserSettingsService } from '../system/user/user.settings.service';
import {
  appendClassesToBody,
  CipoTheme,
  CipoThemeMode,
  findMenuItemByCode,
  getMenuItems,
  Theme,
} from './helpers.store';
import { TenantStore } from './tenant.store';

// Temporary sollution until context refactoring
export type UserWithLastContractContext = User & {
  lastContractContext?: ContextInfo;
};

type UserState = {
  userData: UserWithLastContractContext;
  menu: {
    isOpen: boolean;
    items: MenuModel[];
    activeMenuItem: MenuItem;
  };
  reloadContext?: boolean;
  isLoading: boolean;
  angularJsRoute: boolean;
  hideHeader?: boolean;
  unreadNotifications?: number;
  devMode: boolean;
  theme: Theme;
  language?: string;
  settings: UserSettings;

  //   Temporary prop used for tracking close event of the dialog from js
  dialogData?: unknown;
};

const initialState: UserState = {
  userData: null,
  menu: {
    isOpen: false,
    items: [],
    activeMenuItem: null,
  },
  isLoading: false,
  angularJsRoute: false,
  reloadContext: false,
  hideHeader: false,
  unreadNotifications: 0,
  devMode: false,
  theme: {
    color: 'default',
    mode: 'light',
  },
  language: 'en-US',
  settings: undefined,
  dialogData: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState(initialState),

  withComputed(({ userData, settings, menu }, tenantStore = inject(TenantStore)) => {
    const getTenantSettingValue = (id: TenantSettingsEnum): TenantSettingValue | undefined => {
      const tenantSetting = tenantStore.settings().find(s => s.id === id);
      return tenantSetting?.calculatedValue ?? tenantSetting?.value;
    };

    const calculatedUserSettings = (): UserSettings => {
      const dateFormat = getTenantSettingValue(TenantSettingsEnum.date_format);
      const timeFormat = getTenantSettingValue(TenantSettingsEnum.time_format);
      const decimalSeparator = getTenantSettingValue(TenantSettingsEnum.decimal_separator);
      const thousandSeparator = getTenantSettingValue(TenantSettingsEnum.thousand_separator);
      const loadMore = settings()?.loadMore ?? getTenantSettingValue(TenantSettingsEnum.load_more);

      return {
        dateFormat: dateFormat,
        timeFormat: timeFormat,
        datetimeFormat: dateFormat && timeFormat ? `${dateFormat} ${timeFormat}` : undefined,
        decimalSeparator: decimalSeparator,
        thousandSeparator: thousandSeparator,
        loadMore: loadMore,
      } as UserSettings;
    };

    return {
      tenantId: computed(() => userData()?.tenantId ?? 0),
      contract: computed(() => userData()?.context?.contract),
      currencyId: computed(
        () =>
          userData()?.context?.contract?.currencyId ??
          userData()?.context?.project?.currencyId ??
          userData()?.context?.program?.currencyId ??
          userData()?.defaultCurrencyId,
      ),
      menuItemsList: computed(() => getMenuItems(menu.items() ?? [])),
      calculatedUserSettings: computed(() => calculatedUserSettings()),
    };
  }),

  withMethods(
    (
      store,
      userService = inject(UserApiService),
      userSettingsService = inject(UserSettingsService),
      router = inject(Router),
    ) => {
      const setActiveMenuItem = (activeMenuItem: MenuItem) =>
        patchState(store, ({ menu, userData }) => {
          const newContext = userData.lastContractContext ?? userData.context;
          if (!activeMenuItem.moduleId) {
            sessionStorage.removeItem(MODULE);
          } else {
            sessionStorage.setItem(MODULE, activeMenuItem.moduleId?.toString());
          }
          return {
            menu: { ...menu, activeMenuItem },
            userData: {
              ...userData,
              context: newContext,
              lastContractContext: newContext?.contract ? newContext : userData.lastContractContext,
            },
          };
        });

      const reloadMenu = () =>
        userService.getUserMenu(store.contract().id).subscribe(items => {
          patchState(store, ({ menu }) => ({ menu: { ...menu, items } }));
        });

      const getContractIdFromRoute = () => {
        // this will be removed after implementing these pages as angular components
        // or could be modified to read contract id from the route
        const route = router.routerState.snapshot.url;
        if (
          route.includes('/dm/') ||
          route.includes('/sov/') ||
          route.includes('/progresspayment/') ||
          route.includes('/managementreports/')
        ) {
          return Number(route.split('/')[3]);
        }
        return undefined;
      };

      const getContractFromStoreAndRoute = (isContextChanged: boolean) => {
        let contractId = store.contract()?.id;
        if (isContextChanged) {
          return of({ contractId });
        }

        const contractIdFromRoute = getContractIdFromRoute();

        if (store.tenantId() && contractIdFromRoute) {
          // verify if the contract id from the route is different than the one stored in the store
          if (contractIdFromRoute != contractId) {
            contractId = contractIdFromRoute;

            // patch the context from the route and store it
            patchState(store, ({ userData }) => ({
              userData: { ...userData, context: { contract: { id: contractId } } as ContextInfo },
            }));
          }
        }
        return of({ contractId });
      };

      // all the requests needed after loading the user data
      const reloadUserData = (isContextChanged: boolean) => {
        getContractFromStoreAndRoute(isContextChanged).subscribe(({ contractId }) => {
          // load only if we have a contract id and a tenant id
          if (store.tenantId()) {
            contractId = isNaN(Number(contractId)) ? 0 : Number(contractId);
            forkJoin({
              menu: userService.getUserMenu(contractId),
              unreadNotifications: userService.getUnreadNotifications(contractId),
            }).subscribe(({ menu, unreadNotifications }) => {
              patchState(store, state => ({
                unreadNotifications,
                menu: { ...state.menu, items: menu },
                userData: { ...state.userData, isLoading: false },
              }));
            });
          }
        });
      };

      const getCalculatedValues = (rawSettings: KeyValueModel<TenantSettingsEnum, string>[]): UserSettings => {
        let settings: UserSettings = {} as UserSettings;
        [...(rawSettings || [])].forEach(rawSetting => getCalculatedValue(rawSettings, settings, rawSetting.key));
        return settings;
      };

      const getCalculatedValue = (
        rawSettings: KeyValueModel<TenantSettingsEnum, string>[],
        settings: UserSettings,
        id: TenantSettingsEnum,
      ) => {
        switch (id) {
          case TenantSettingsEnum.load_more:
            settings.loadMore = getBool(rawSettings, TenantSettingsEnum.load_more);
            break;
        }
      };

      const getBool = (rawSettings: KeyValueModel<TenantSettingsEnum, string>[], id: TenantSettingsEnum): boolean => {
        const s = rawSettings
          .find(s => s.key === id)
          ?.value.toString()
          .toLowerCase();
        return ['true', 'yes', '1'].includes(s);
      };

      return {
        setIsLoading: (isLoading: boolean) => patchState(store, { isLoading }),
        setUser: () => {
          patchState(store, { isLoading: true, userData: null });
          userService.getUserData().subscribe(userData => {
            patchState(store, { isLoading: false, userData });
            reloadUserData(false);
          });
          userSettingsService.getUserSettings().subscribe(rawSettings => {
            patchState(store, { settings: getCalculatedValues(rawSettings) });
          });
        },
        refreshUserData: () => userService.getUserData().subscribe(userData => patchState(store, { userData })),
        updateUserData: (userData: UserWithLastContractContext) => {
          patchState(store, { userData });
        },

        setActiveMenuItem,
        setActiveMenuByUrl: (url: string) => {
          if (!url) {
            return;
          }
          const items = store.menuItemsList();
          const activeMenuItem = items.find(item => item.urlPath?.endsWith(url));
          if (activeMenuItem) {
            setActiveMenuItem(activeMenuItem);
          }
        },
        reloadMenu,
        toggleMenu: () => {
          const isOpen = !store.menu.isOpen();
          if (isOpen) {
            localStorage.setItem(MENU_SHOW_KEY, 'true');
          } else {
            localStorage.removeItem(MENU_SHOW_KEY);
          }
          patchState(store, ({ menu }) => ({ menu: { ...menu, isOpen } }));
        },
        hasPermission: (permission: Permission) =>
          !!store.menu.activeMenuItem()?.operations?.find(op => op.id === permission),
        getModuleByCode: (code: RouteCodeEnum) => findMenuItemByCode(store.menuItemsList(), code),
        enableDemo: () => {
          const items = store.menu.items();
          if (items[items.length - 1]?.id !== -999) {
            const demoMenu: MenuItem = {
              id: -999,
              name: 'Demo',
              children: NewDemoRoutes,
            };
            patchState(store, ({ menu }) => ({ menu: { ...menu, items: [...menu.items, demoMenu] }, devMode: true }));
          }
        },

        setReloadContext: (reloadContext: boolean) => patchState(store, { reloadContext }),
        //   this should be reviewed when refactoring context (context should always include all the available data)
        updateContext: (context: ContextInfo) => {
          if (context.contract) {
            patchState(store, ({ userData }) => ({
              userData: { ...userData, context, lastContractContext: context, isLoading: true },
            }));
            reloadUserData(true);
          } else {
            patchState(store, ({ userData }) => ({
              userData: { ...userData, context, lastContractContext: userData.lastContractContext },
            }));
          }
        },
        setAngularJsRoute: (angularJsRoute: boolean) => patchState(store, { angularJsRoute }),
        setHideHeader: (hideHeader: boolean) => patchState(store, { hideHeader }),
        setUnreadNotifications: (unreadNotifications: number) => patchState(store, { unreadNotifications }),

        updateTheme: (newTheme: Partial<Theme>) => {
          const theme = { ...store.theme(), ...newTheme };
          patchState(store, { theme });
          if (newTheme.color) {
            localStorage.setItem(CIPO_THEME, newTheme.color);
          }
          if (newTheme.mode) {
            localStorage.setItem(CIPO_THEME_MODE, newTheme.mode);
          }
          appendClassesToBody({ mode: theme.mode, theme: theme.color });
        },

        loadSettings: () =>
          userSettingsService
            .getUserSettings()
            .subscribe(rawSettings => patchState(store, { settings: getCalculatedValues(rawSettings) })),
        getSettings: () => store.settings(),

        //   until angularJs is removed
        closeJsDialog: (dialogData: unknown) => patchState(store, { dialogData }),
        setLanguage: (language: string) => patchState(store, { language }),
      };
    },
  ),

  withHooks((store, router = inject(Router), tenantStore = inject(TenantStore)) => ({
    onInit: () => {
      const theme = (localStorage.getItem(CIPO_THEME) as CipoTheme) ?? 'default';
      const mode = (localStorage.getItem(CIPO_THEME_MODE) as CipoThemeMode) ?? 'light';
      store.updateTheme({ color: theme, mode });

      const menuEffect = effect(
        () => {
          const menuItems = store.menu.items();
          if (!!menuItems.length && localStorage.getItem(MENU_SHOW_KEY)) {
            store.toggleMenu();
            menuEffect.destroy();
          }
        },
        { allowSignalWrites: true, manualCleanup: true },
      );

      effect(
        () => {
          if (store.menu.items()?.length) {
            store.setActiveMenuByUrl(router.url.substring(1));
          }
        },
        { allowSignalWrites: true },
      );

      const selectedTenantEffect = effect(
        () => {
          if (tenantStore.selectedTenant()) {
            store.setUser();
            selectedTenantEffect.destroy();
          }
        },
        { allowSignalWrites: true, manualCleanup: true },
      );
    },
  })),
);
