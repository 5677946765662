import { Component, computed, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { debounceTime } from 'rxjs';
import { AttachmentModel } from '../../../models/module/fields/main';
import { CipoImgBase64 } from '../../../shared/components/img-base64/img-base64.component';
import { DEBOUNCE_TIMES } from '../../../shared/consts';
import { CipoDialogModule, CipoFormModule } from '../../../shared/modules';
import { NotificationService } from '../../../shared/services';
import { FileVersionCommentModel } from './attachments-note.model';
import { AttachmentsNodeService } from './attachments-note.service';

@Component({
  selector: 'cipo-attachments-note',
  standalone: true,
  providers: [AttachmentsNodeService],
  imports: [CipoDialogModule, CipoFormModule, CipoImgBase64, NgxSkeletonLoaderModule],
  templateUrl: './attachments-note.component.html',
  styleUrls: ['./attachments-note.component.scss'],
})
export class AttachmentsNoteComponent implements OnInit {
  attachmentsNodeService = inject(AttachmentsNodeService);
  notificate = inject(NotificationService);
  translate = inject(TranslateService);

  skeleton = signal(Array(5));
  loading = signal<boolean>(false);
  files = signal<FileVersionCommentModel[]>([]);
  @Input('entityinstanceid') entityInstanceId: number;
  @Input('fieldid') fieldId: number;
  @Input('onlyview') onlyView: boolean;
  @Input('parententityinstanceid') parentEntityInstanceId: number;
  @Input('attachments') attachments: AttachmentModel[];
  @Output('onclose') onClose: EventEmitter<FileVersionCommentModel[]> = new EventEmitter<FileVersionCommentModel[]>();

  searchControl = new FormControl('');
  searchValue = signal('');
  filteredFiles = computed(() => {
    const searchValue = this.searchValue();
    return this.files()?.filter(
      o =>
        o.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        o.comment?.toLowerCase()?.includes(searchValue?.toLowerCase()),
    );
  });

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIMES.short))
      .subscribe(val => this.searchValue.set(val));

    // get files
    if (this.entityInstanceId) {
      this.loading.set(true);

      this.attachmentsNodeService
        .getAttachmentsNote(this.entityInstanceId, this.fieldId, this.parentEntityInstanceId)
        .subscribe({
          next: f => {
            this.files.set(f);
            this.addMissingAttachments();
            this.loading.set(false);
          },
          error: () => {
            this.loading.set(false);
          },
        });
    } else {
      this.addMissingAttachments();
    }
  }

  addMissingAttachments() {
    if (this.attachments?.length) {
      for (let index = 0; index < this.attachments.length; index++) {
        const att = this.attachments[index];
        const existingAtt = this.files().find(f => f.fileId == att.id);
        if (existingAtt) {
          // add note in case we don't have one
          if (!existingAtt.comment && att.note) {
            existingAtt.comment = att.note;
          }
        } else {
          this.files().push({
            fileId: att.id,
            versionId: att.versionId,
            name: att.name,
            mimeType: att.mimeType,
            thumbnail: att.thumbnail200,
            fieldInstanceId: null,
            comment: att.note,
            notAutoSaved: false,
          });
        }
      }
    }
  }

  clearInput() {
    this.searchControl.setValue('');
  }

  onCloseClick() {
    this.onClose.emit(this.files());
  }

  noteChanged(file: FileVersionCommentModel) {
    file.notAutoSaved = true;
  }
}
