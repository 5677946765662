import { cipo } from 'cipo';

cipo.directive("attachmentMenu", function (AttachmentHandler, PSPDFKIT_CONFIG, MIME_TYPES, OFFICE_MIME_TYPES, fileService, PROJECT_FILE_MODULE_ID, userService, $mdDialog) {
    return {
        restrict: "E",
        // replace: true,
        scope: {
            "attachments": "=",
            "actions": "=",
            "entityInstanceId": "=?",
            "fieldId": "=?",
            "showNotes": "=?",
        },
        templateUrl: "/ng/views/directives/system/attachment-menu.html",
        controller: function ($scope) {

            // Variables
            $scope.actions = $scope.actions || {};
            $scope.showMultipleSaveButton = true;
            $scope.isLoading = false;
            $scope.attachmentHandler = new AttachmentHandler({
                isLoading: function (isLoading) { $scope.isLoading = isLoading; }
            });
            $scope.isShowRemoveButton = false;

            if ($scope.attachments === null || $scope.attachments === undefined) {
                // set empty list if no attachments are defined
                $scope.attachments = [];
            }
            else if (!Array.isArray($scope.attachments)) {
                // set a list of one attachment if single object is set
                $scope.attachments = [$scope.attachments];
            }
            // filter for valid attachments
            $scope.attachments = $scope.attachments.filter(a => a !== null && a !== undefined);

            if ($scope.attachments && $scope.attachments.length > 0) {
                $scope.showMultipleSaveButton = $scope.attachments[0].showMultipleSave;
                $scope.projectFilesName = $scope.attachments[0].projectFilesName;
                $scope.myDriveName = $scope.attachments[0].myDriveName;
            }

            $scope.hasPermissionOnProjectFile = userService.getModuleIdentifierById(PROJECT_FILE_MODULE_ID);

            $scope.isShowRemoveButton = $scope.actions.hasOwnProperty('remove') && $scope.actions.remove != null;

            $scope.permissionToModifyNotes = userService.getPermissions(userService.getModuleIdentifierById(userService.system.selectedModuleId))?.hasOwnProperty('Update');

            // Define remove function if provided in actions
            if ($scope.isShowRemoveButton) {
                $scope.remove = function (x) {
                    if (!$scope.isLoading)
                        $scope.actions.remove(x);
                }
            }

            // parse
            $scope.viewableAttachments = [];
            if ($scope.attachments != null && $scope.attachments.length && !($scope.attachments.length == 1 && !$scope.attachments[0])) {
                for (var i = 0; i < $scope.attachments.length; i++) {
                    // TODO: Perform mimeType null check, and default to a class if null?
                    if ($scope.attachments[i].mimeType != null)
                        $scope.attachments[i].cssClass = $scope.attachments[i].mimeType.replace(/\//g, '-').replace(/\./g, '-');

                    if (PSPDFKIT_CONFIG.MIME_TYPES.includes($scope.attachments[i].mimeType))
                        $scope.viewableAttachments.push($scope.attachments[i]);
                }
            }

            $scope.canDisplayInViewer = function (mimeType) {
                return PSPDFKIT_CONFIG.MIME_TYPES.includes(mimeType) || OFFICE_MIME_TYPES.includes(mimeType);
            }

            $scope.canDisplayInBrowser = function (mimeType) {
                return MIME_TYPES.PREVIEWABLE.includes(mimeType);
            }

            $scope.open = function (x) {
                if (PSPDFKIT_CONFIG.MIME_TYPES.includes(x.mimeType)) {
                    $scope.attachmentHandler.openAttachment(x.id, $scope.viewableAttachments);
                } else if (OFFICE_MIME_TYPES.includes(x.mimeType)) {
                    $scope.attachmentHandler.open(x);
                }
            }

            $scope.openInNewTab = function (x) {
                $scope.attachmentHandler.openInNewTab(x);
            }

            $scope.save = function (x) {
                $scope.attachmentHandler.save(x);
            }

            $scope.selectFolderSingle = function (projectFile, attachment) {
                var attachments = [];
                attachments.push(attachment.id);

                $scope.selectFolder(projectFile, attachments, [attachment]);
            }

            $scope.selectFolder = function (projectFile, attachments, attachmentsModel) {

                fileService.selectFolder(projectFile, attachments, attachmentsModel);
            }

            $scope.showMultipleAttachments = function (attachments, isSaveTo, isProjectFiles) {
                fileService.showMultipleAttachments(attachments, isSaveTo, isProjectFiles);
            }

            $scope.isContractClosed = function () {
			    return userService.isContractClosed(userService.system.userdata.contractId);
            }

            function AttachmentNoteController($mdDialog, $log) {
                var ctrl = this;
            
                ctrl.closeDialog = function() {
                  $mdDialog.hide();
                };
              }

            $scope.showNoteInfo = function(attachment) {
                $mdDialog.show({
                    template:
                      '<md-dialog>' +
                      `  <md-dialog-content style="margin: 16px;"><div style="margin-bottom: 16px; font-size: 14px; font-weight: bold;">${attachment.name}</div>${(attachment.note ?? '').replaceAll('\n', '<br/>')}</md-dialog-content>` +
                      '  <md-dialog-actions>' +
                      '    <md-button ng-click="ctrl.closeDialog()" class="md-primary">' +
                      '      Close' +
                      '    </md-button>' +
                      '  </md-dialog-actions>' +
                      '</md-dialog>',
                    locals: { },
                    fullscreen: true,
                    focusOnOpen: true,
                    multiple: true,
                    escapeToClose: true,
                    clickOutsideToClose: true,
                    controller: AttachmentNoteController,
                    controllerAs: 'ctrl',
                  });
            }

            $scope.addNotes = function() {
                var controller = 'addAttachmentsNoteController';
                var templateUrl = '/ng/views/admin/modals/addAttachmentsNote.html'
                var parentEntityInstanceId = userService.system.context.contract?.entityInstanceId ?? userService.system.context.project?.entityInstanceId ?? userService.system.context.program?.entityInstanceId;

                $mdDialog.show({
                    locals: { fieldId: $scope.fieldId, entityInstanceId: $scope.entityInstanceId, parentEntityInstanceId: parentEntityInstanceId, onlyView: !$scope.showNotes || !$scope.permissionToModifyNotes, attachments: $scope.attachments },
                    controller: controller,
                    templateUrl: templateUrl,
                    parent: angular.element(document.body),
                    fullscreen: true,
                    focusOnOpen: true,
                    multiple: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                // hide function
                .then(function (attachments) {
                    if (attachments && attachments.length) {
                        ($scope.attachments ?? []).forEach(element => {
                            element.note = attachments.find(a => a.fileId == element.id)?.comment;
                        });

                        $scope.attachments = [...$scope.attachments || []];
                    }
                });
            }
        }
    }
});
