<mat-dialog-content fxLayout="column" class="full-height dialog-content m-b">
    <div fxLayoutAlign="end" class="m-b-md">
        <mat-form-field class="cipo-input hide-subscript p-0">
            <mat-label>{{ "general.search" | translate }}</mat-label>
            <input matInput [formControl]="searchControl" />
            @if (searchControl.value) {
                <button matSuffix [title]="'general.clear' | translate" mat-icon-button (click)="clearInput()">
                    <mat-icon>close</mat-icon>
                </button>
            } @else {
                <mat-icon matSuffix>search</mat-icon>
            }
        </mat-form-field>
    </div>
    <div fxFlex class="overflow--auto m-t-xl">
        @if (loading()) {
            @for (item of skeleton(); track $index) {
                <div [class]="{ 'border-top--solid': $index > 0 }" class="p-t-lg">
                    <ngx-skeleton-loader
                        class="m-b-sm"
                        [theme]="{
                            width: '100%',
                            height: '17px',
                            margin: '0px',
                        }" />
                    <div fxLayout fxLayoutGap="16px">
                        <ngx-skeleton-loader
                            [theme]="{
                                width: '100px',
                                height: '70px',
                                margin: '0px',
                            }" />
                        <ngx-skeleton-loader
                            fxFlex
                            class="m-b-sm"
                            [theme]="{
                                width: '100%',
                                height: '68px',
                                margin: '0px',
                            }" />
                    </div>
                </div>
            }
        } @else {
            @for (file of filteredFiles(); track $index) {
                <div [class]="{ 'border-top--solid': $index > 0, 'p-b-xxl': file.notAutoSaved }" class="p-t-lg">
                    <label class="m-b-sm">{{ file.name }}</label>
                    <div fxLayout fxLayoutGap="16px">
                        <cipo-img-base64
                            [base64]="file.thumbnail"
                            [mimetype]="file.mimeType"
                            [width]="100"
                            [height]="70" />
                        <mat-form-field class="full-width" appearance="outline">
                            <textarea
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="10"
                                matInput
                                [(ngModel)]="file.comment"
                                (change)="noteChanged(file)"
                                [disabled]="onlyView"
                                [placeholder]="onlyView ? '' : ('fileDialog.firstNote' | translate)"></textarea>
                            @if (file.notAutoSaved) {
                                <mat-hint>{{ "fileDialog.notSaved" | translate }}</mat-hint>
                            }
                        </mat-form-field>
                    </div>
                </div>
            }
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center">
    <button mat-raised-button (click)="onCloseClick()">{{ "general.close" | translate }}</button>
</mat-dialog-actions>
