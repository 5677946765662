<div fxLayout="column" fxLayoutAlign="space-between">
    <mat-dialog-content fxFlex="grow">
        <mat-tab-group mat-stretch-tabs="false">
            <mat-tab label="{{ 'fileDialog.properties' | translate }}">
                <div fxLayout fxLayoutAlign="space-between start" class="m-t-md">
                    @if (loading()) {
                        <ngx-skeleton-loader
                            class="m-b-md m-t-sm"
                            [theme]="{ width: '615px', height: '24px', margin: '0px' }"></ngx-skeleton-loader>
                    } @else {
                        <h5>
                            <cipo-img-base64
                                class="m-r-sm"
                                [mimetype]="file.mimeType"
                                [width]="20"
                                [height]="20"></cipo-img-base64>
                            <span>{{ file.name }}</span>
                        </h5>
                    }
                    <div fxLayout fxLayoutGap="8px">
                        @if (hasUpdatePermission()) {
                            <button mat-button [disabled]="loading()" (click)="uploadFile()">
                                <mat-icon>upload</mat-icon>
                                {{ "fileDialog.upload" | translate }}
                            </button>
                        }
                        @if (!loading() && !isCurrentVersion() && hasUpdatePermission()) {
                            <button mat-button (click)="duplicateVersion()">
                                <mat-icon class="material-symbols-outlined">replace_image</mat-icon>
                                {{ "fileDialog.setCurrentVersion" | translate }}
                            </button>
                        }
                        @if (file?.versions?.length > 1) {
                            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="loading()">
                                <mat-icon>file_copy</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    mat-menu-item
                                    *ngFor="let version of file.versions"
                                    (click)="changeVersion(version.id)"
                                    [disabled]="isCurrentVersion(version.id)">
                                    <cipo-img-base64
                                        [base64]="version.thumbnail50"
                                        [width]="20"
                                        [height]="20"></cipo-img-base64>
                                    {{ version.updatedOn | itvDateTime }}
                                    @if (version.isCurrent) {
                                        {{ "fileDialog.current" | translate }}
                                    }
                                </button>
                            </mat-menu>
                        }
                    </div>
                </div>
                <div fxLayout>
                    <div class="m-t-xxl" fxFlex="30" fxLayout="column" fxLayoutAlign="center center">
                        @if (loading()) {
                            <ngx-skeleton-loader
                                [theme]="{ width: '200px', height: '140px', margin: '0px' }"></ngx-skeleton-loader>
                        } @else {
                            <cipo-img-base64
                                [base64]="file.thumbnail200"
                                [mimetype]="file.mimeType"
                                [width]="file.thumbnail200 ? 200 : 140"
                                [height]="140"></cipo-img-base64>
                            <button mat-button [disabled]="loading()" class="m-t-md" (click)="downloadFile()">
                                <mat-icon>download</mat-icon>
                                {{ "fileDialog.download" | translate }}
                            </button>
                        }
                    </div>
                    <div fxFlex>
                        <div fxLayout fxLayoutAlign="center center" class="p-y-sm m-t-xxl">
                            <label fxFlex="25">{{ "fileDialog.location" | translate }}</label>
                            @if (loading()) {
                                <ng-container [ngTemplateOutlet]="skeleton"></ng-container>
                            } @else {
                                <div fxFlex fxLayoutGap="8px">
                                    <mat-icon>folder</mat-icon>
                                    <p class="m-a-0" fxFlex>{{ file.folderName }}</p>
                                </div>
                            }
                        </div>
                        <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                            <label fxFlex="25">{{ "fileDialog.fileSize" | translate }}</label>
                            @if (loading()) {
                                <ng-container [ngTemplateOutlet]="skeleton"></ng-container>
                            } @else {
                                <p class="m-a-0" fxFlex>{{ file.length | itvFileSize }}</p>
                            }
                        </div>
                        <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                            <label fxFlex="25">{{ "fileDialog.createdBy" | translate }}</label>
                            @if (loading()) {
                                <ng-container [ngTemplateOutlet]="skeleton"></ng-container>
                            } @else {
                                <p class="m-a-0" fxFlex>{{ file.createdBy }}</p>
                            }
                        </div>
                        <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                            <label fxFlex="25">{{ "fileDialog.updatedOn" | translate }}</label>
                            @if (loading()) {
                                <ng-container [ngTemplateOutlet]="skeleton"></ng-container>
                            } @else {
                                <p class="m-a-0" fxFlex>
                                    {{ file.updatedOn | itvDateTime }} {{ "fileDialog.by" | translate }}
                                    {{ file.updatedBy }}
                                </p>
                            }
                        </div>
                        <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                            <label fxFlex="25">{{ "fileDialog.createdOn" | translate }}</label>
                            @if (loading()) {
                                <ng-container [ngTemplateOutlet]="skeleton"></ng-container>
                            } @else {
                                <p class="m-a-0" fxFlex>{{ file.createdOn | itvDateTime }}</p>
                            }
                        </div>
                        <div fxLayout fxLayoutAlign="center center" class="border-top--solid p-y-sm">
                            <label fxFlex="25">{{ "fileDialog.version" | translate }}</label>
                            @if (loading()) {
                                <ng-container [ngTemplateOutlet]="skeleton"></ng-container>
                            } @else {
                                <p class="m-a-0" fxFlex>{{ file.version }}</p>
                            }
                        </div>
                        <div fxLayout fxLayoutAlign="center start" class="border-top--solid p-y-sm">
                            <label fxFlex="25">{{ "fileDialog.note" | translate }}</label>
                            @if (loading()) {
                                <p fxFlex class="m-a-0">
                                    <ngx-skeleton-loader
                                        [theme]="{ width: '95%', height: '68px', margin: '0px' }"></ngx-skeleton-loader>
                                </p>
                            } @else {
                                <mat-form-field class="m-a-0 p-r-lg" fxFlex appearance="outline">
                                    <textarea
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="10"
                                        matInput
                                        [(ngModel)]="file.note"
                                        [disabled]="isDestinationFolder"
                                        (ngModelChange)="noteChanged.set(true)"
                                        [placeholder]="'fileDialog.firstNote' | translate"></textarea>
                                </mat-form-field>
                            }
                        </div>
                    </div>
                </div>
                @if (!entityInstanceId && !loading()) {
                    <div fxLayout="column" class="border-top--solid m-t-md">
                        <div fxLayoutAlign="space-between center" class="m-t-md">
                            <h4>
                                {{
                                    (file.sharedUsersId?.length ? "fileDialog.sharedWith" : "fileDialog.notShared")
                                        | translate
                                }}
                            </h4>
                            @if (file.sharedUsersId?.length) {
                                <button mat-button (click)="removeSharedUsers()">
                                    <mat-icon class="material-symbols-outlined">share_off</mat-icon>
                                    {{ "fileDialog.unshare" | translate }}
                                </button>
                            }
                        </div>
                        <mat-chip-set class="m-t-sm">
                            @for (user of file.sharedUsersId; track user.userId) {
                                <mat-chip (removed)="removeSharedUser(user.userId)">
                                    @if (user?.logo) {
                                        <cipo-img-base64 matChipAvatar [base64]="user.logo" [width]="20" />
                                    } @else {
                                        <mat-icon matChipAvatar>person</mat-icon>
                                    }
                                    {{ user.name }}
                                    @if (user.canBeUnShared) {
                                        <button matChipRemove>
                                            <mat-icon class="material-icons-outlined">delete</mat-icon>
                                        </button>
                                    }
                                </mat-chip>
                            }
                        </mat-chip-set>
                    </div>
                }
            </mat-tab>
            @if (canChangePermissions && !loading() && entityInstanceId) {
                <mat-tab label="{{ 'fileDialog.permissions' | translate }}">
                    <app-permissions
                        [fileId]="file.id"
                        [fileParentFolderId]="file.parentId"
                        [entityInstanceId]="entityInstanceId"
                        [rootModuleId]="rootModuleId"
                        (permissionChanged)="permissionChangedEvent($event)"></app-permissions>
                </mat-tab>
            }
        </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions fxFlex="none" class="m-t-md" fxLayout fxLayoutAlign="end center" fxLayoutGap="8px">
        <button mat-raised-button (click)="onCloseClick()">{{ "general.close" | translate }}</button>
        <button
            mat-raised-button
            [disabled]="!permissionChanged && !noteChanged()"
            color="primary"
            (click)="onSaveClick()">
            {{ "general.save" | translate }}
        </button>
    </mat-dialog-actions>
</div>

<ng-template #skeleton>
    <p fxFlex class="m-a-0">
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px', margin: '0px' }"></ngx-skeleton-loader>
    </p>
</ng-template>
