import { cipo } from 'cipo';

cipo.factory("WorkflowEntityInstance", function (Model, WorkflowScreen, EntityFieldsLayout, $q, userService, URI,
    Message, $document, Permissions, rememberManagerService, $mdDialog, PdfHandler) {
    //constructor extending Model
    var WorkflowEntityInstance = Model.extend(function (obj) {
        // properties
        var obj = obj || {};

        var moduleFiltered = userService.system.modulesList.filter(e => e.moduleId == obj.moduleId) || [];
        
        this.hybridModuleIds = [-1, -10, -23];
        this.moduleId = obj.moduleId || null;
        this.moduleName = obj.moduleName || null;
        this.moduleDocumentNumber = obj.moduleDocumentNumber || null;
        this.perContract = obj.perContract || false;
        this.moduleCode = obj.moduleCode || (moduleFiltered && moduleFiltered.length > 0 ? moduleFiltered[0].code : 'item');
        this.entityInstanceId = obj.entityInstanceId || 0;
        this.communication = obj.communication || [];
        this.fromEntityInstanceId = obj.fromEntityInstanceId || 0;
        this.contractId = obj.contractId || 0;
        this.entityInstanceIdList = obj.entityInstanceIdList || null;
        this.presetsIdList = obj.presetsIdList || null;
        this.screenId = obj.screenId;
        this.manageWorkflowGroups = moduleFiltered && moduleFiltered.length > 0 ? moduleFiltered[0].manageWorkflowGroups : false;
        this.workflowGroups = [];
        this.workflowGroupId = null;
        this.workflowGroupName = null;
        this.nonBindingSignature = (userService.system.userdata.signature || {}).signatureText;
        this.startTransitionTimer = null;
        this.updateStartScreenTimer = null;
        this.triggerTransitionTimer = null;
        this.triggerMultiTransitionTimer = null;
        this.onFormInit = obj.onFormInit || function (data) { };
        this.onFormChange = obj.onFormChange || function (fieldId, newValue, data, fields) { };
        this.onStartScreenUpdated = obj.onStartScreenUpdated || function () { };
        this.onTransitionTriggered = obj.onTransitionTriggered || function () { };

        userService.system.selectedModuleId = this.moduleId;

        this.isModified = false;
        // this.loadingTransitions = true;

        if (this.perContract && !this.contractId)
            this.contractId = userService.system.userdata.contractId || 0;

        this.pdfHandler = new PdfHandler({
            moduleId: this.moduleId,
            moduleName: this.moduleName,
            moduleCode: this.moduleCode,
            contractId: this.contractId || userService.system.userdata.contractId || 0,
            contractNo: userService.system.context.contract?.no,
        });
    });

    var confirm = function (title, text, isMultiple) {
        return $mdDialog.confirm({
            title: title,
            textContent: text,
            ariaLabel: 'Confirm Dialogue',
            ok: 'Proceed',
            cancel: 'Cancel',
            multiple: isMultiple || false
        });
    }

    WorkflowEntityInstance.prototype.init = function (loadActivity, loadTransitions) {
        // this needs to resolve for contract
        var self = this;
        var p = $q.defer();

        loadActivity = loadActivity != undefined ? loadActivity : true;
        loadTransitions = loadTransitions != undefined ? loadTransitions : true;

        self.isDraft = null;
        self.isLoaded = false;

        self.header = {
            contractName: null,
            contractNumber: null,
            moduleAbbr: null,
            moduleDescription: null,
            moduleDocumentNumber: null,
            moduleName: null,
            projectName: null,
            projectNumber: null,
            originalModuleDocumentNumber: null,

            revisions: [],
            ballInCourt: []
        }

        self.generalInfo = {};
        self.selectedDocNo = null;
        self.workflow = {
            transitions: [],
            transitionsInstancesList: [],
            pastAssignments: []
        }

        self.transition = {
            properties: { id: null, name: null },
            screen: null,
            isStarted: false
        }
        self.approvalsList = [];
        self.signInfo = {};

        self.propertiesScreen = new WorkflowScreen({
            moduleId: self.moduleId,
            entityInstanceId: self.entityInstanceId,
            fromEntityInstanceId: self.fromEntityInstanceId,
            contractId: self.contractId,
            isStartScreen: true,
            screenId: self.screenId,
            onFormInit: self.onFormInit,
            onFormChange: self.onFormChange,
        });

        if (self.presetId)
            self.propertiesScreen.presetValuesLookup = self.presetProperties.valuesLookup;

        if (self.manageWorkflowGroups)
            self.getWorkflowGroups();

        if (self.entityInstanceId) {
            self.get_header();
            self.get_general_info(loadActivity, loadTransitions)
                .then(function (r) {
                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                });
        } else {
            self.isDraft = true;
            if (self.perContract && userService.system.userdata.contractId) {
                self.header.contractName = userService.system.context.contract?.name;
            }

            self.initInfo(loadActivity, loadTransitions)
                .then(function () {
                    p.resolve();
                })
                .catch(function (e) { p.reject(e); });
        }

        // removed call, not sure if anyone anywhere waits for this to resolve, so:

        return p.promise;
    }

    WorkflowEntityInstance.prototype.getWorkflowGroups = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULES.WORKFLOW_GROUPS;
        self.presetProperties = {};
        self[dataURL.method](dataURL, { url: { id: self.moduleId }, urltype: 'obj' })
            .then(function (r) {
                self.workflowGroups = r;

                // Auto set worflow for draft
                if (self.isDraft && !self.workflowGroupId) {
                    var group = self.workflowGroups.find(w => w.isDefault);
                    self.workflowGroupId = group?.key;
                    self.workflowGroupName = group?.value;
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            })

        return p.promise;
    }

    WorkflowEntityInstance.prototype.setWorkflowGroup = function (group) {
        var self = this;

        // In case user want's to change an existing entity instance, only in edit mode and not draft
        if (!self.isDraft && self.propertiesScreen.form.editMode) {
            var newWorkflowGroupName = self.workflowGroups.find(w => w.key == group.key)?.value;
            $mdDialog.show(confirm('Change Workflow', `You are about to change the workflow ${self.workflowGroupName}. Existing documents will continue on this workflow and all new documents associated with this contract will follow workflow ${newWorkflowGroupName}. Are you sure?`, true))
                .then(function () {
                    // Set new values
                    self.workflowGroupId = group.key;
                    self.workflowGroupName = newWorkflowGroupName;
                });
        }
        else {
            // For draft, just let to set new workflow group
            self.workflowGroupId = group.key;
            self.workflowGroupName = self.workflowGroups.find(w => w.key == group.key)?.value;
        }
    }

    WorkflowEntityInstance.prototype.openPDFMarkup = function (document, event) {
        var self = this;
        $mdDialog.show({
            locals: {
                module: userService.system.modules['FCM'],
                documentId: document.documentId,
                fileUrl: null,
                fileId: document.fileId,
                title: document.name,
                mimeType: 'application/pdf',
                entityInstanceId: userService.system.context.contract?.entityInstanceId,
                operations: self.operations
            },
            controller: 'pspdfkitViewerController',
            templateUrl: '/ng/views/admin/modals/pspdfkitViewer.html',
            parent: angular.element(document.body),
            targetEvent: event,
            fullscreen: true,
            escapeToClose: false,
            clickOutsideToClose: false,
            disableParentScroll: true,
            multiple: true
        });
    }

    WorkflowEntityInstance.prototype.openEntityInstance = function (element) {
        if (element.moduleAbbr == 'PP' || element.moduleAbbr == 'SOVI' || element.moduleAbbr == 'SOV')
            return;

        var itemToSend = {
            itemId: element.entityInstanceId,
            fromItem: 0
        }

        $mdDialog.show({
            locals: {
                item: itemToSend,
                module: { moduleId: element.moduleId, code: element.moduleAbbr, name: element.moduleName },
                contractId: element.contractId,
                smallerFullscreen: false,
                screenId: element.screenId
            },
            controller: 'SaveWorkflowEntityInstanceController',
            templateUrl: '/ng/views/dynamics/modals/saveWorkflowEntityInstance.html',
            parent: angular.element(document.body),
            fullscreen: true,
            multiple: true,
            escapeToClose: false,
            clickOutsideToClose: false
        });
    }

    WorkflowEntityInstance.prototype.get_PresetData = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_PRESETS.GET;
        self.presetProperties = {};
        self[dataURL.method](dataURL, { url: { id: self.presetId }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                for (var key in r) {
                    if (r.hasOwnProperty(key)) {
                        self.presetProperties[key] = r[key];
                    }
                }
                // if (!r.documentNumber) self.properties.documentKey = 0;

                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            })

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_header = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_HEADER;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.header[key] = r[key];
                        }
                    }

                    $document[0].title = 'CIPO® Software - ' + self.header.moduleName + ' ' + (self.header.moduleDocumentNumber || "");
                }

                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.initInfo = function (loadActivity, loadTransitions) {
        var self = this;
        var p = $q.defer();
        self.availableDocNums = null;

        if (!self.entityInstanceId && !self.presetId && !self.generalInfo.isStartedByPreset) {
            self.get_DocNum();
        }

        self.isLoaded = false;

        if (loadActivity && self.entityInstanceId)
            self.get_activity_summary();
        if (loadTransitions)
            self.get_transitions();

        self.propertiesScreen.init(self.isDraft && !self.generalInfo.approvalId)
            .then(function () {
                self.isLoaded = true;

                if (self.generalInfo.isContractClosedOrArchived || userService.isContractClosed(self.contractId)) {
                    self.propertiesScreen.form.editMode = false;
                }

                if (self.propertiesScreen.data && self.propertiesScreen.data.fields && self.propertiesScreen.data.fields.length && !self.propertiesScreen.isDraftState) {
                    var relations = [];
                    for (var i = 0; i < self.propertiesScreen.data.fields.length; i++) {
                        if (self.propertiesScreen.data.fields[i].allowChanges) relations.push(self.propertiesScreen.data.fields[i]);
                    }
                }

                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            });
        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_general_info = function (loadActivity, loadTransitions) {
        var self = this;
        var dataURL = self.moduleCode == 'CT' ? URI.CT.GET_GENERAL_INFO : URI.MODULE.GET_GENERAL_INFO;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key))
                            self.generalInfo[key] = r[key];
                    }

                    if (r.hasManualDocumentAssignment || r.isClosedState) self.get_assignments();
                    if (r.isClosedState) self.get_signers();
                    if (!r.isDraftState) self.get_assignments_history();
                    if (r.entityInstanceId && !r.isDraftState) {
                        self.get_pdf_markups();
                    }

                    if ((r.permissions || []).length) {
                        self.operations = {};
                        for (var j = 0; j < r.permissions.length; j++) {
                            Object.assign(self.operations, Permissions[r.permissions[j]]);
                        }
                    }
                    self.isDraft = r.isDraftState;
                    if (self.isDraft && !self.presetId && !self.generalInfo.isStartedByPreset) self.get_DocNum();
                    if (self.generalInfo.isStartedByPreset) {
                        self.selectedDocNo = {
                            number: self.generalInfo.documentNumberId,
                            value: self.generalInfo.documentNumber,
                            minor: self.generalInfo.documentNoMinor
                        }
                    }
                    self.propertiesScreen.isDraftState = self.generalInfo.isDraftState;

                    if (self.generalInfo.approvalId && self.generalInfo.canApprove) {
                        self.propertiesScreen.setupApprovalForm();
                    }

                    var defaultWorkflowGroup = self.workflowGroups.find(w => w.isDefault);
                    self.workflowGroupId = r.workflowGroupId ?? defaultWorkflowGroup?.key;
                    self.workflowGroupName = r.workflowGroupName ?? defaultWorkflowGroup?.value;

                    self.initInfo(loadActivity, loadTransitions).then(function () {
                        p.resolve();
                    });
                }
                else {
                    p.resolve();
                }
            })
            .catch(function (e) {
                self.isLoaded = true;
                self.loadErrorMsg = 'There was a problem retrieving the document';
                try {
                    if (e.data?.messages?.entityInstance && e.data.messages.entityInstance.length) {
                        self.loadErrorMsg += ': ' + e.data.messages.entityInstance[0].message;
                    }
                    else { 
                        self.loadErrorMsg += ': ' + e.data.messages[0].message; 
                    }
                }
                catch { }
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_assignments = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_ASSIGNMENT;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.assignments = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.assignments[key] = r[key];
                }
                for (var i = 0; i < (r.manualAssignUsers || []).length; i++) {
                    self.assignments.lookup[r.manualAssignUsers[i].key] = r.manualAssignUsers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;

    }

    WorkflowEntityInstance.prototype.get_assignments_history = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_ASSIGNMENT_HISTORY;
        self.workflow.pastAssignments = [];
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r || []).length; i++) {
                    if (r[i].validFrom)
                        r[i].validFrom = userService.formatDateTime(r[i].validFrom);
                    if (r[i].validThrough)
                        r[i].validThrough = userService.formatDateTime(r[i].validThrough);
                }

                self.workflow.pastAssignments = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;

    }

    WorkflowEntityInstance.prototype.get_pdf_markups = function () {
        var self = this;
        var dataURL = URI.PDF_MARKUP.GET_EI_LINKS;
        self.pdf_markups = [];
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                self.pdf_markups = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_signers = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_SIGNERS;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.signInfo = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.signInfo[key] = r[key];
                }
                for (var i = 0; i < (r.signers || []).length; i++) {
                    self.signInfo.lookup[r.signers[i].key] = r.signers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;

    }

    WorkflowEntityInstance.prototype.get_transitions = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_TRANSITIONS;
        var urlParams = {
            workflowId: userService.system.workflowId,
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.workflow.transitions = [];
        self.loadingTransitions = true;
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                var hasPermToArchive = false;
                var hasPermToClose = false;
                for (var i = 0; i < userService.system.modules[self.moduleCode].operations.length; i++) {
                    //check if the user is in a Role that has the permission to create/udate/delete archives
                    if (userService.system.modules[self.moduleCode].operations[i].name == 'Export Data') {
                        hasPermToArchive = true;
                    }

                    if (userService.system.modules[self.moduleCode].operations[i].name == 'Close Contract') {
                        hasPermToClose = true;
                    }
                }

                // add actions on contract module, for a non draft instance
                if (self.moduleCode == 'CT' && self.entityInstanceId && !self.isDraft) {
                    // add archive actions if has permissions
                    if (hasPermToArchive) {
                        r.push({ name: 'Export Data' });
                        r.push({ name: 'View Data Exports' });
                    }

                    if (hasPermToClose && self.generalInfo.isClosedState && !self.generalInfo.isClosed && !self.generalInfo.isArchived) {
                        r.push({ name: 'Close Contract' });
                    }

                    if (hasPermToClose && self.generalInfo.isClosed && !self.generalInfo.isArchived) {
                        r.push({ name: 'Reopen Contract' });
                    }

                    if (hasPermToClose && self.generalInfo.isClosed && !self.generalInfo.isArchived) {
                        r.push({ name: 'Archive Contract' });
                    }
                }

                self.workflow.transitions = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;

    }

    WorkflowEntityInstance.prototype.get_activity_summary = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_ACTIVITY_SUMMARY;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        self.activityListLookup = {};
        self.isActivityLoaded = false;

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                //approvals
                for (var i = 0; i < (r.approvals || []).length; i++) {
                    if (r.approvals[i].approvedOn.charAt(r.approvals[i].approvedOn.length - 1) != 'Z') r.approvals[i].approvedOn = r.approvals[i].approvedOn + 'Z';
                    r.approvals[i].approvedOn = userService.formatDateTime(r.approvals[i].approvedOn);

                }
                self.approvalsList = (r || {}).approvals || [];

                //relations
                var relations = [];
                for (var i = 0; i < (r.relations || []).length; i++) {
                    if (r.relations[i].records > 0) relations.push(r.relations[i]);
                }

                self.propertiesScreen.loadManagers(relations);

                if (self.moduleId == -23)
                    self.propertiesScreen.loadManagerModule(-10);

                if (self.moduleId == -10)
                    self.propertiesScreen.loadManagerModule(-1);

                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isActivityLoaded = true;
            });

        return p.promise;

    }

    WorkflowEntityInstance.prototype.get_activity = function () {
        var self = this;
        var dataURL = URI.MODULE.GET_ACTIVITY;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.setFieldInfo = function (actionInstance, fieldId) {
        var self = this;
        for (var i = 0; i < actionInstance.preferredFields.length; i++) {
            if (actionInstance.preferredFields[i].key != fieldId) actionInstance.preferredFields[i].isActive = false;
        }
        for (var key in actionInstance.screen.form.fieldsList) {
            if (actionInstance.screen.form.fieldsList.hasOwnProperty(key) && key != fieldId)
                actionInstance.screen.form.fieldsList[key].isInfo = false;
        }
        (actionInstance.screen.form.fieldsList[fieldId] || {}).isInfo = !(actionInstance.screen.form.fieldsList[fieldId] || {}).isInfo;

    }

    WorkflowEntityInstance.prototype.changePreferredValue = function (actionInstance, field) {
        var self = this;
        var p = $q.defer();
        field.isBusy = true;
        var dataURL = URI.MODULE.SET_PREFERRED_VALUE;
        var urlParams = {
            actionId: field.actionId,
            actionInstanceId: actionInstance.actionInstanceId,
            fieldId: field.key,
            contractId: self.contractId

        }

        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                p.resolve();
                Message.info('Preferred value saved successfully');
                /*if (r && self.activityListLookup[r]) {
                    for (var i = 0; i < self.activityListLookup[r].preferredFields.length; i++) {
                        if (self.activityListLookup[r].preferredFields[i].key == field.key) {
                            self.activityListLookup[r].preferredFields[i].isUsed = false;
                            break;
                        }

                    }
                }*/
                //
            }).catch(function (e) {
                Message.dberror(e);
                field.isUsed = !field.isUsed;
                p.reject();
            }).finally(function () {
                field.isBusy = false;
            })

        return p.promise;
    }
    
    WorkflowEntityInstance.prototype.get_past_transition_info = function (actionInstance) {
        var self = this;
        var p = $q.defer();
        actionInstance.loading = true;
        var urlParams = {
            actionInstanceId: actionInstance.key || actionInstance.actionInstanceId,
            contractId: self.contractId,
            noFields: actionInstance.isDraft || false
        };
        var dataURL = URI.MODULE.GET_TRANSITION_INSTANCE;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        actionInstance[key] = r[key];
                }
                self.set_transition_info(actionInstance);

                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                actionInstance.loading = false;
            });


        return p.promise;
    }

    WorkflowEntityInstance.prototype.set_transition_info = function (actionInstance) {
        var self = this;
        if (actionInstance.fields) {
            actionInstance.screen = new WorkflowScreen({
                moduleId: self.moduleId,
                entityInstanceId: self.entityInstanceId,
                contractId: self.contractId,
                screenId: self.screenId,
                actionInstanceId: actionInstance.actionInstanceId,
                onFormInit: self.onFormInit,
                onFormChange: self.onFormChange,
            });
            actionInstance.screen.data = { fields: actionInstance.fields };
            actionInstance.screen.setupForm(false);

            if ((actionInstance.preferredFields || []).length && actionInstance.screen.form.fieldsList) {
                for (var i = 0; i < actionInstance.preferredFields.length; i++) {
                    (actionInstance.screen.form.fieldsList[actionInstance.preferredFields[i].key] || {}).isHighlighted = true;
                }
            }
        }
        if (actionInstance.type == 1 && !actionInstance.stateTypeId) {
            actionInstance.msg = actionInstance.contactEmail 
                ? 'The ' + self.moduleCode + ' was generated from e-mail sent by ' + actionInstance.contactEmail 
                : 'The ' + self.moduleCode + ' draft has entered the workflow';
        }
    }

    WorkflowEntityInstance.prototype.set_DocNum = function (n) {
        var self = this;
        self.selectedDocNo = n;
        self.docNoError = "";
    }

    WorkflowEntityInstance.prototype.startChangeNumber = function () {
        var self = this;
        self.isChangingNumber = true;
        self.isBusy = true;
        self.isLoadingNumbers = true;
        self.get_docNumDict(!self.generalInfo.canUseAuto)
            .then(function (r) {
                self.availableDocNums = r || [];
            })
            .catch(function (e) {

            })
            .finally(function () {
                self.isLoadingNumbers = false;
            })
    }

    WorkflowEntityInstance.prototype.saveChangeNumber = function () {
        var self = this;
        self.isSavingNumber = true;
        var dataURL = URI.MODULE.EDIT_DOCUMENT_NUMBER;

        self[dataURL.method](dataURL, {
            url: {}, urltype: 'obj', body: {
                entityInstanceId: self.generalInfo.entityInstanceId,
                documentNumber: self.selectedDocNo.key ? self.selectedDocNo.number : null,
                documentNoMinor: self.selectedDocNo.key ? self.selectedDocNo.minor : null
            }
        },
            { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                Message.info('Document Number updated successfully');
                self.init();
                self.cancelChangeNumber();
                self.isModified = true;

            })
            .catch(function (e) {
                Message.dberror(e);

            })
            .finally(function () {
                self.isSavingNumber = false;
            })
    }

    WorkflowEntityInstance.prototype.cancelChangeNumber = function () {
        var self = this;
        self.isChangingNumber = false;
        self.isBusy = false;
        self.selectedDocNo = null;

    }

    WorkflowEntityInstance.prototype.get_docNumDict = function (noAutoPresent) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE.DOC_NUM_AVAILABLE;

        var params = {
            moduleId: self.moduleId,
            entityInstanceId: self.generalInfo.entityInstanceId || 0,
        };
        if (self.perContract) params.contractId = self.contractId;

        self[dataURL.method](dataURL, { url: params, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                if (!noAutoPresent) r.unshift({ key: 0, value: "Auto", order: 0, selected: true });
                p.resolve(r);
            })
            .catch(function (e) {

                p.reject(e);
            });

        return p.promise;

    }

    WorkflowEntityInstance.prototype.get_DocNum = function () {
        var self = this;
        var p = $q.defer();
        self.selectedDocNo = { key: 0, value: "Auto", order: 0, selected: true };
        self.docNumsLookup = {};

        self.get_docNumDict()
            .then(function (result) {
                if (result) self.availableDocNums = result;
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {
                        self.docNumsLookup[result[i].key] = result[i];
                    }
                }
                var selectedNoKey = typeof self.generalInfo.documentNumberId == 'undefined' ?
                    self.generalInfo.documentNumberId : self.generalInfo.documentNumberId + '-' + self.generalInfo.documentNoMinor;
                if (selectedNoKey) {
                    if (self.docNumsLookup[selectedNoKey])
                        self.selectedDocNo = self.docNumsLookup[selectedNoKey];
                    else self.docNoError = "The requested document number (" + self.generalInfo.documentNumber + " ) is no longer available";
                }
                // self.availableDocNums.unshift({ key: 0, value: "Auto", order: 0, selected: true });

                p.resolve();
            })
            .catch(function (e) {

                p.reject(e);
            });
    }

    WorkflowEntityInstance.prototype.sign_Doc = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_SIGNATURE.GET;
        var signId;
        self.signatureOptions = {
            sign: false
        };
        for (var i = 0; i < self.signInfo.signers.length; i++) {
            if (self.signInfo.signers[i].id == userService.system.userdata.id && !self.signInfo.signers[i].signed) {
                signId = self.signInfo.signers[i].signId;
                break;
            }
        }
        self[dataURL.method](dataURL, { id: signId })
            .then(function (result) {
                self.signatureOptions = {
                    sign: true,
                    signatureId: result.signId,
                    clientId: result.clientId,
                    url: result.signatureUrl,
                    // redirect: true,
                    isProd: result.isProd,
                    setSigned: function (sid) {
                        self.put(URI.MODULE_SIGNATURE.SET_SIGNED, { url: { id: sid }, urltype: 'obj' })
                            .then(function () { self.init(); })
                            .catch(function () { })
                    },
                    callback: function () {

                        return;
                    }
                };
            })
            .catch(function (e) {
                Message.dberror(e);
            });
    }

    WorkflowEntityInstance.prototype.getEntityInstanceAsRow = function () {
        return {
            entity_instance_id: this.entityInstanceId,
            full_doc_num: this.header.moduleDocumentNumber,
            closed_on: this.generalInfo.isClosedState ? 'yes' : null,
            screen_id: this.generalInfo.screenId,
        };
    }

    WorkflowEntityInstance.prototype.pdfHandlerDownload = function () {
        this.pdfHandler.download(this.getEntityInstanceAsRow());
    }

    WorkflowEntityInstance.prototype.pdfHandlerEmail = function (scope) {
        var self = this;

        var emailSendWatch = scope.$watch(function () { return self.pdfHandler.isEmailSend; }, function (newParam, oldParam) {
            if (newParam) {
                self.pdfHandler.isEmailSend = false;
                emailSendWatch();
                self.get_activity_summary();
            }
        });

        self.pdfHandler.email(scope, self.getEntityInstanceAsRow());
    }

    WorkflowEntityInstance.prototype.pdfHandlerPrint = function () {
        this.pdfHandler.printDocuments(this.getEntityInstanceAsRow());
    }

    WorkflowEntityInstance.prototype.get_Transitions = function () {
        var self = this;
        var p = $q.defer();
        //self.loadingTransitions = true;
        var params = {
            moduleId: self.moduleId,
            entityInstanceId: self.entityInstanceId,
            contractId: self.contractId
        }

        var transitionsURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
            URI[self.moduleCode.toUpperCase()].TRANSITIONS : URI.MODULE.TRANSITIONS;

        self[transitionsURL.method](transitionsURL.toString(), params)
            .then(function (result) {
                self.workflow.transitions = result.transitions;
                if (result.pastTransitions && result.pastTransitions.length) {
                    for (var i = 0; i < result.pastTransitions.length; i++) {
                        if (i == result.pastTransitions.length - 1) {
                            result.pastTransitions[i].screen = null;
                            result.pastTransitions[i].msg = 'The ' + self.moduleCode + ' draft has entered the workflow';
                        } else {
                            result.pastTransitions[i].screen = new WorkflowScreen({
                                moduleId: self.moduleId,
                                entityInstanceId: self.entityInstanceId,
                                contractId: self.contractId,
                                screenId: self.screenId,
                                onFormInit: self.onFormInit,
                                onFormChange: self.onFormChange,
                            });
                            result.pastTransitions[i].screen.data = { fields: result.pastTransitions[i].fields };
                            result.pastTransitions[i].screen.setupForm(false);
                            // result.pastTransitions[i].collapsedDetails = true;

                        }
                        if ((result.pastTransitions[i].approvals || []).length) {
                            for (var j = 0; j < result.pastTransitions[i].approvals.length; j++) {
                                // 
                                result.pastTransitions[i].approvals[j].approvedOn = userService.formatDateTime(result.pastTransitions[i].approvals[j].approvedOn);
                            }
                        }
                    }
                }
                if (result.pastAssignments && result.pastAssignments.length) {
                    for (var i = 0; i < result.pastAssignments.length; i++) {

                        if (result.pastAssignments[i].validFrom)
                            result.pastAssignments[i].validFrom = userService.formatDateTime(result.pastAssignments[i].validFrom);
                        if (result.pastAssignments[i].validThrough)
                            result.pastAssignments[i].validThrough = userService.formatDateTime(result.pastAssignments[i].validThrough);
                    }
                }
                if ((result.draftApprovals || []).length) {
                    for (var j = 0; j < result.draftApprovals.length; j++) {
                        // 
                        result.draftApprovals[j].approvedOn = userService.formatDateTime(result.draftApprovals[j].approvedOn);
                    }
                }
                self.workflow.transitionsInstancesList = result.pastTransitions;
                self.workflow.pastAssignments = result.pastAssignments;
                self.workflow.draftApprovals = result.draftApprovals;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                // self.loadingTransitions = false;
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.excludeRolesFromTransition = function (t) {
        var self = this;
        t.exclusionRoles = angular.copy(self.propertiesScreen.rolesDict);
        t.showExcludeRoles = true;
        if ((t.roleToExcludeIds || []).length) {
            for (var i = 0; i < t.exclusionRoles.length; i++) {
                if (t.roleToExcludeIds.indexOf(t.exclusionRoles[i].key) != -1)
                    t.exclusionRoles[i].isUsed = true;
            }
        }
    }

    WorkflowEntityInstance.prototype.cancelExcludeRoles = function (t) {
        var self = this;
        t.showExcludeRoles = false;
    }

    WorkflowEntityInstance.prototype.syncExcludeRoles = function (t) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE.SYNC_EXCLUDED_ROLES;
        t.loading = true;
        var roleToExcludeIds = [];
        for (var i = 0; i < t.exclusionRoles.length; i++) {
            if (t.exclusionRoles[i].isUsed)
                roleToExcludeIds.push(t.exclusionRoles[i].key);
        }
        var params = {
            url: {
                moduleId: self.moduleId,
                actionInstanceId: t.actionInstanceId,
                contractId: self.contractId
            },
            body: roleToExcludeIds,
            urltype: 'obj'
        }
        self[dataURL.method](dataURL, params)
            .then(function (result) {
                t.roleToExcludeIds = roleToExcludeIds;
                t.showExcludeRoles = false;
                self.get_past_transition_info(t);
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            });
        return p.promise;
    }

    WorkflowEntityInstance.prototype.getActionEmailInformations = function (actionId) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self.loadingEmailData = true;
        var urlParams = {
            actionId: actionId,
            entityInstanceId: self.entityInstanceId,
            contractId: self.contractId
        }

        var dataURL = URI.MODULE.GET_EMAIL_DATA;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' })
            .then(function (result) {
                self.emailActionData = result;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
                self.loadingEmailData = false;
            })

        return p.promise;
    }

    WorkflowEntityInstance.prototype.startTransitionWithDelay = function (transition, role, callbackSuccess, callbackError) {
        var self = this;
        clearTimeout(self.startTransitionTimer);
        self.startTransitionTimer = setTimeout(function() {
            if (self.propertiesScreen && self.propertiesScreen.anyFieldBeingRecalculated()) {
                // formulas are being calculated, delay start transition
                self.startTransitionWithDelay(transition, role, callbackSuccess, callbackError);
            }
            else {
                // no formulas are being calculated, start transition now
                self.startTransition(transition, role)
                    .then(function (autoTrigger) {
                        if (callbackSuccess) {
                            callbackSuccess(autoTrigger);
                        }
                    })
                    .catch(function (e) { 
                        if (callbackError) {
                            callbackError(e);
                        }
                    });
            }
        }, 100);
    }

    WorkflowEntityInstance.prototype.startTransition = function (transition, role) {
        var self = this;
        var p = $q.defer();
        self.transition.properties = transition;
        self.transition.selectedRole = role;
        var autoTrigger = true;

        if (self.isDraft || transition.isRevise || parseInt(transition.stateTypeId) === 0) {
            // transition form is the document properties form
            if (self.isDraft && !transition.isDraft) {
                self.transition.screen = self.propertiesScreen;
                self.transition.screen.transitionId = self.transition.properties.id;
                self.transition.screen.form.validate();
                if (self.transition.screen.form.isValid) {
                    self.transition.transitionInProgress = true;
                    self.transition.screen.loaded = false;
                    self.transition.screen.form.editMode = false;
                    self.transition.screen.init()
                        .then(function () {
                            self.transition.screen.loaded = true;
                            if (!self.transition.screen.data?.fields?.length 
                                && self.transition.screen.data?.hideRolesNotify
                                && !(self.transition.screen.data?.requiresNonBindingSignature && !self.nonBindingSignature)
                                || !self.transition.screen.data?.fields?.length 
                                && !self.transition.screen.data?.emailsDataSource?.length
                                && !self.transition.screen.data?.hideRolesNotify
                                && !(self.transition.screen.data?.requiresNonBindingSignature && !self.nonBindingSignature)) {
                                p.resolve(autoTrigger);
                            }
                            else {
                                self.transition.isStarted = true;
                                p.resolve(false);
                            }
                        })
                        .catch(function (e) { p.reject(e); self.transition.screen.loaded = true; self.transition.transitionInProgress = false; });
                }
                else {
                    p.reject();
                }
            }
            else {
                self.transition.isStarted = true;

                if (self.isDraft)
                    self.transition.screen = self.propertiesScreen;
                p.resolve(autoTrigger);
            }
        } else {
            // transition needs a screen
            var screenProperties = {
                moduleId: self.moduleId,
                transitionId: self.transition.properties.id,
                entityInstanceId: self.entityInstanceId,
                contractId: self.contractId,
                selectedRoleId: self.transition.selectedRole ? self.transition.selectedRole.key : null,
                screenId: self.screenId,
                onFormInit: self.onFormInit,
                onFormChange: self.onFormChange,
            }
            if (transition.isComment || transition.isEmail)
                screenProperties.actionId = self.transition.properties.id;
            else
                screenProperties.transitionId = self.transition.properties.id;

            self.transition.screen = new WorkflowScreen(screenProperties);

            self.transition.transitionInProgress = true;

            self.transition.screen.init()
                .then(function () {
                    if (!self.transition.screen.data?.fields?.length 
                        && self.transition.screen.data?.hideRolesNotify
                        && !(self.transition.screen.data?.requiresNonBindingSignature && !self.nonBindingSignature)
                        || !self.transition.screen.data?.fields?.length 
                        && !self.transition.screen.data?.emailsDataSource?.length
                        && !self.transition.screen.data?.hideRolesNotify
                        && !(self.transition.screen.data?.requiresNonBindingSignature && !self.nonBindingSignature)) {
                        p.resolve(autoTrigger);
                    }
                    else {
                        self.transition.isStarted = true;
                        p.resolve(false);
                    }
                })
                .catch(function (e) { p.reject(e); self.transition.transitionInProgress = false; });
        }

        return p.promise;
    }

    WorkflowEntityInstance.prototype.clearTransition = function () {
        this.transition = {
            properties: { id: null, name: null },
            screen: null,
            isStarted: false
        };

        if (this.isDraft) {
            (this.propertiesScreen.data || {}).requiresNonBindingSignature = false;
            this.propertiesScreen.form.editMode = true;
            this.propertiesScreen.form.clearErrors();
        }

        return;
    }

    WorkflowEntityInstance.prototype.cancelUpdateStartScreen = function () {
        var self = this;
        self.propertiesScreen.data = self.propertiesScreen.backupData;
        self.propertiesScreen.setupForm(false);

        // Set workflow group from back-up
        self.workflowGroupId = self.workflowGroupIdBackUp;
        self.workflowGroupName = self.workflowGroupNameBackUp;
        // Reset back-up
        self.workflowGroupIdBackUp = null;
        self.workflowGroupNameBackUp = null;
    }


    WorkflowEntityInstance.prototype.startUpdateStartScreen = function () {
        var self = this;
        var p = $q.defer();
        // self.propertiesScreen.form.editMode = true;
        self.propertiesScreen.form.loading = true;
        self.propertiesScreen.backupData = angular.copy(self.propertiesScreen.data);

        self.propertiesScreen.get_fields(true)
            .then(function (r) {
                self.propertiesScreen.data = r;
                self.propertiesScreen.setupForm(true);
                // Create back-up in case we hit cancel button
                self.workflowGroupIdBackUp = self.workflowGroupId;
                self.workflowGroupNameBackUp = self.workflowGroupName;

                p.resolve();
            }).catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                self.propertiesScreen.form.loading = false;
            })
        return p.promise;
    }

    WorkflowEntityInstance.prototype.updateStartScreenWithDelay = function () {
        var self = this;
        clearTimeout(self.updateStartScreenTimer);
        self.updateStartScreenTimer = setTimeout(function() {
            if (self.propertiesScreen && self.propertiesScreen.anyFieldBeingRecalculated()) {
                // formulas are being calculated, delay update screen
                self.updateStartScreenWithDelay();
            }
            else {
                // no formulas are being calculated, update screen now
                self.updateStartScreen();
            }
        }, 100);
    }

    WorkflowEntityInstance.prototype.updateStartScreen = function () {
        var self = this;
        var p = $q.defer();
        self.propertiesScreen.form.validate();
        if (self.propertiesScreen.form.isValid) {
            self.propertiesScreen.form.loading = true;
            var params = {
                url: {
                    moduleId: self.moduleId,
                    // transitionId: self.transition.properties.id,
                    entityInstanceId: self.entityInstanceId,
                    contractId: self.contractId
                },
                body: {
                    values: self.propertiesScreen.valuesDictionary,
                    workflowGroupId: self.workflowGroupId,
                    emails: [],
                    groups: [],
                    roleToExcludeIds: []
                },

                urltype: 'obj'
            }

            var dataURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                URI[self.moduleCode.toUpperCase()].UPDATE : URI.MODULE.UPDATE;

            self[dataURL.method](dataURL.toString(), params)
                .then(function () {
                    self.isModified = true;
                    self.init();
                    self.onStartScreenUpdated();
                    p.resolve();
                }).catch(function (e) {
                    self.propertiesScreen.form.catch(e);
                    self.propertiesScreen.form.loading = false;
                    p.reject(e);
                })
        }
        return p.promise;
    }

    WorkflowEntityInstance.prototype.triggerTransitionWithDelay = function (callbackSuccess, callbackError) {
        var self = this;
        clearTimeout(self.triggerTransitionTimer);
        self.triggerTransitionTimer = setTimeout(function() {
            if (self.transition && self.transition.screen && self.transition.screen.anyFieldBeingRecalculated()) {
                // formulas are being calculated, delay trigger transition
                self.triggerTransitionWithDelay(callbackSuccess, callbackError);
            }
            else {
                // no formulas are being calculated, trigger transition now
                self.triggerTransition()
                    .then(function(r) {
                        if (callbackSuccess) {
                            callbackSuccess(r);
                        }
                    })
                    .catch(function(e) {
                        if (callbackError) {
                            callbackError(e);
                        }
                    })
            }
        }, 100);
    }

    WorkflowEntityInstance.prototype.triggerTransition = function () {
        var self = this;
        var p = $q.defer();
        var trigger = true;
        self.transition.transitionIsTriggered = true;
        // temporary
        try {
            var params = {
                url: {
                    moduleId: self.moduleId,
                    workflowId: userService.system.workflowId,
                    // transitionId: self.transition.properties.id,
                    entityInstanceId: self.entityInstanceId,
                    contractId: self.contractId,
                    roleId: self.transition.selectedRole ? self.transition.selectedRole.key : null
                },
                body: self.transition.screen ? {
                    values: self.transition.screen.valuesDictionary,
                    emails: self.transition.screen.emails.emails.filter((e) => !Number(e)),
                    groups: self.transition.screen.emails.emails.filter((e) => Number(e)),
                    includeAttachments: self.transition.screen.emails.includeAttachments,
                    roleToExcludeIds: self.transition.screen.roleToExcludeIds.roleToExcludeIds,
                    screenId: self.screenId,
                    workflowGroupId: self.workflowGroupId
                } : {
                    screenId: self.screenId,
                    workflowGroupId: self.workflowGroupId
                },
                urltype: 'obj'
            }

            if (self.transition.properties.isComment || self.transition.properties.isEmail)
                params.url.actionId = self.transition.properties.id;
            else
                params.url.transitionId = self.transition.properties.id;

            if (self.presetId) params.url.presetId = self.presetId;

            if (self.selectedDocNo) {
                params.body.documentNumber = self.selectedDocNo.key == 0 ? null : self.selectedDocNo.number;
                params.body.documentNoMinor = self.selectedDocNo.key == 0 ? null : self.selectedDocNo.minor;
            }
            if (((self.transition.screen || {}).roleToExcludeIds || {}).assignee)
                params.body.manualAssignUser = self.transition.screen.manualAssignUsersLookup[self.transition.screen.roleToExcludeIds.assignee];
        }
        catch (e) {
            self.transition.transitionIsTriggered = false;
        }

        // Check if we have any workflow groups and needs to be set
        if (self.manageWorkflowGroups && !self.workflowGroupId) {
            Message.warning("Please select a workflow.");
            self.transition.transitionIsTriggered = false;
            self.clearTransition();
            return;
        }

        if (self.transition.screen && (self.transition.screen.form || self.transition.screen.exclusionForm)) {
            (self.transition.screen.form || self.transition.screen.exclusionForm).loading = true;
        }

        if (self.transition.screen && self.transition.screen.form && (!self.transition.properties.isDraft || self.hybridModuleIds.indexOf(self.moduleId) !== -1)) {
            self.transition.screen.form.validate();
            if (!self.transition.screen.form.isValid) {
                trigger = false;
                self.transition.isError = true;
            } else { self.transition.isError = false; }
        } else trigger = true;

        if (trigger) {
            var saveTransitionURL;
            if (self.transition.properties.isDraft) {
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_DRAFT : URI.MODULE.SAVE_DRAFT;

            } else if (self.transition.properties.isRevise) {
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_REVISE : URI.MODULE.SAVE_REVISE;
            } else if (self.transition.properties.isComment) {
                saveTransitionURL = URI.MODULE.ADD_COMMENT;
            } else if (self.transition.properties.isEmail) {
                saveTransitionURL = URI.MODULE.SEND_EMAIL;
            } else {
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_TRANSITION_SCREEN : URI.MODULE.SAVE_TRANSITION_SCREEN;
            }

            if (self.presetId && !self.generalInfo.entityInstanceId)
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_PRESET : URI.MODULE.SAVE_PRESET;

            self.message = "Action in progress...";
            self[saveTransitionURL.method](saveTransitionURL.toString(), params)
                .then(function (result) {
                    var changeId = null;
                    if (result) changeId = parseInt(self.entityInstanceId) == parseInt(result) ? null : result;
                    self.isModified = true;
                    self.presetId = null;
                    self.presetProperties = {};
                    self.onTransitionTriggered(result);
                    if (changeId) {
                        rememberManagerService.saveInstanceId(changeId);
                    }
                    self.transition.transitionInProgress = false;
                    self.transition.transitionIsTriggered = false;
                    p.resolve(changeId);
                })
                .catch(function (e) {
                    self.transition.isError = true;
                    self.transition.isStarted = false;

                    if (self.transition.screen && self.transition.screen.form) {
                        (self.transition.screen.form || self.transition.screen.exclusionForm).catch(e);
                        (self.transition.screen.form || self.transition.screen.exclusionForm).loading = false;
                    }
                    else {
                        Message.dberror(e);
                    }

                    if (self.isDraft)
                        self.transition.screen.form.editMode = true;

                    if (e.data && e.data.messages && e.data.messages.length && typeof e.data.messages[0].code !== "undefined" && e.data.messages[0].code === "InvalidNonBindingSignature") {
                        self.transition.screen.data.requiresNonBindingSignature = true;
                    }
                    self.onTransitionTriggered(null, e);

                    self.transition.transitionInProgress = false;
                    self.transition.transitionIsTriggered = false;

                    p.reject(e);
                })
                .finally(function () {
                    self.message = "";
                });
        } else {
            self.transition.screen.form.loading = false;
            self.transition.transitionInProgress = false;
            self.transition.transitionIsTriggered = false;
            self.transition.isStarted = false;
            p.reject('Form not valid');
        }

        return p.promise;
    }

    WorkflowEntityInstance.prototype.triggerMultiTransitionWithDelay = function (callbackSuccess, callbackError) {
        var self = this;
        clearTimeout(self.triggerMultiTransitionTimer);
        self.triggerMultiTransitionTimer = setTimeout(function() {
            if (self.transition && self.transition.screen && self.transition.screen.anyFieldBeingRecalculated()) {
                // formulas are being calculated, delay trigger transition
                self.triggerMultiTransitionWithDelay(callbackSuccess, callbackError);
            }
            else {
                // no formulas are being calculated, trigger transition now
                self.triggerMultiTransition()
                    .then(function(r) {
                        if (callbackSuccess) {
                            callbackSuccess(r);
                        }
                    })
                    .catch(function(e) {
                        if (callbackError) {
                            callbackError(e);
                        }
                    })
        }
        }, 100);
    }

    WorkflowEntityInstance.prototype.triggerMultiTransition = function () {
        var self = this;
        var p = $q.defer();
        var trigger = true;
        // temporary
        try {
            var params = {
                url: {
                    moduleId: self.moduleId,
                    workflowId: userService.system.workflowId,
                    contractId: self.contractId,
                    roleId: self.transition.selectedRole ? self.transition.selectedRole.key : null
                },
                body: self.transition.screen
                    ? {
                        values: self.transition.screen.valuesDictionary,
                        emails: self.transition.screen.emails.emails.filter((e) => !Number(e)),
                        groups: self.transition.screen.emails.emails.filter((e) => Number(e)),
                        includeAttachments: self.transition.screen.emails.includeAttachments,
                        roleToExcludeIds: self.transition.screen.roleToExcludeIds.roleToExcludeIds,
                        entityInstanceIds: self.entityInstanceIdList,
                        screenId: self.screenId,
                        presetIds: self.presetsIdList,
                        workflowGroupId: self.workflowGroupId
                    }
                    : {
                        entityInstanceIds: self.entityInstanceIdList,
                        presetIds: self.presetsIdList,
                        workflowGroupId: self.workflowGroupId
                    },
                urltype: 'obj'
            }

            if (self.transition.properties.isComment) params.url.actionId = self.transition.properties.id;
            else params.url.transitionId = self.transition.properties.id;

            if (self.presetId) params.url.presetId = self.presetId;

            if (self.selectedDocNo) {
                params.body.documentNumber = self.selectedDocNo.key == 0 ? null : self.selectedDocNo.number;
                params.body.documentNoMinor = self.selectedDocNo.key == 0 ? null : self.selectedDocNo.minor;
            }
            if (((self.transition.screen || {}).roleToExcludeIds || {}).assignee)
                params.body.manualAssignUser = self.transition.screen.manualAssignUsersLookup[self.transition.screen.roleToExcludeIds.assignee];
        }
        catch {
        }

        // validate if entity instances and presest were choosed, we can select only presets or only entity instances
        if (self.entityInstanceIdList && self.entityInstanceIdList.length && self.presetsIdList && self.presetsIdList.length) {
            Message.warning("You cannot select presets and documents at the same time !");
            return;
        }

        if (self.transition.screen && (self.transition.screen.form || self.transition.screen.exclusionForm)) {
            (self.transition.screen.form || self.transition.screen.exclusionForm).loading = true;
        }

        if (self.transition.screen && self.transition.screen.form && (!self.transition.properties.isDraft || self.hybridModuleIds.indexOf(self.moduleId) !== -1)) {
            self.transition.screen.form.validate();
            if (!self.transition.screen.form.isValid) {
                trigger = false;
                self.transition.isError = true;
            } else { self.transition.isError = false; }
        } else trigger = true;

        if (trigger) {
            var saveTransitionURL;
            if (self.transition.properties.isDraft) {
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_MULTI_DRAFT : URI.MODULE.SAVE_MULTI_DRAFT;

            } else if (self.transition.properties.isRevise) {
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_MULTI_REVISE : URI.MODULE.SAVE_MULTI_REVISE;
            } else if (self.transition.properties.isComment) {
                saveTransitionURL = URI.MODULE.ADD_COMMENT;
            } else if (self.presetsIdList && self.presetsIdList.length) {
                saveTransitionURL = URI.MODULE.SAVE_MULTI_PRESETS;
            } else {
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_MULTI_TRANSITION_SCREEN : URI.MODULE.SAVE_MULTI_TRANSITION_SCREEN;
            }

            if (self.presetId && !self.generalInfo.entityInstanceId)
                saveTransitionURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
                    URI[self.moduleCode.toUpperCase()].SAVE_PRESET : URI.MODULE.SAVE_PRESET;

            self.message = "Action in progress...";
            self[saveTransitionURL.method](saveTransitionURL.toString(), params)
                .then(function (result) {
                    var changeId = null;
                    if (result) changeId = parseInt(self.entityInstanceId) == parseInt(result) ? null : result;
                    self.isModified = true;
                    self.presetId = null;
                    self.presetProperties = {};
                    p.resolve(changeId);
                })
                .catch(function (e) {
                    self.transition.isError = true;
                    if (self.transition.screen && self.transition.screen.form) {
                        (self.transition.screen.form || self.transition.screen.exclusionForm).catch(e);
                        (self.transition.screen.form || self.transition.screen.exclusionForm).loading = false;

                    }

                    else {
                        self.transition.isStarted = false;
                        Message.dberror(e);
                    }

                    if (typeof e.data.messages[0].code !== "undefined" && e.data.messages[0].code === "InvalidNonBindingSignature") {
                        self.transition.screen.data.requiresNonBindingSignature = true;
                    }
                    p.reject(e);
                })
                .finally(function () {
                    self.message = "";

                });
        } else {
            self.transition.screen.form.loading = false;
            self.transition.transitionInProgress = false;
            self.transition.transitionIsTriggered = false;
            self.transition.isStarted = false;
            p.reject('Form not valid');
        }

        return p.promise;
    }

    WorkflowEntityInstance.prototype.approve = function (isApprove) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            contractId: self.contractId,
        }
        var bodyParams = {
            id: self.generalInfo.approvalId,
            approved: isApprove ? true : false,
            comment: self.propertiesScreen.approvalContent.comment,
            documentNumber: self.selectedDocNo ? self.selectedDocNo.number : null,
            documentNoMinor: self.selectedDocNo ? self.selectedDocNo.minor : null,
            workflowGroupId: self.workflowGroupId
        };

        var dataURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ?
            URI[self.moduleCode.toUpperCase()].DO_APPROVAL : URI.MODULE_APPROVAL.DO_APPROVAL;

        self.propertiesScreen.approvalForm.loading = true;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams })
            .then(function (result) {
                self.init();

                self.isModified = true;

                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                self.propertiesScreen.approvalForm.loading = false;
                // self.propertiesScreen.form.catch(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    WorkflowEntityInstance.prototype.comment = function () {
        var self = this;
        var p = $q.defer();


        if (self.propertiesScreen.approvalContent.comment) {
            self.isBusy = true;
            var urlParams = {
                contractId: self.contractId,
            }
            var bodyParams = {
                entityInstanceId: self.entityInstanceId,
                comment: self.propertiesScreen.approvalContent.comment
            };
            self.propertiesScreen.approvalForm.loading = true;
            self[URI.MODULE_APPROVAL.ADD_COMMENT.method](URI.MODULE_APPROVAL.ADD_COMMENT, { url: urlParams, urltype: 'obj', body: bodyParams })
                .then(function () {
                    self.get_activity_summary();
                    self.propertiesScreen.approvalContent.comment = "";
                    self.isModified = true;

                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                    Message.dberror(e);

                })
                .finally(function () {
                    self.isBusy = false;
                    self.propertiesScreen.approvalForm.loading = false;
                })
        }
        else {
            Message.error("Please add comment");
            p.reject();
        }

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_payments = function () {
        var self = this;
        var p = $q.defer();
        // self.isBusy = true;
        var urlParams = {
            entityInstanceId: self.entityInstanceId
        }

        self[URI.PROGRESS_PAYMENT.ENTITY_INSTANCE_PP.method](URI.PROGRESS_PAYMENT.ENTITY_INSTANCE_PP, { url: urlParams, urltype: 'obj' })
            .then(function (result) {
                //self.init();
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                // self.isBusy = false;
            })

        return p.promise;
    }

    WorkflowEntityInstance.prototype.cancelApprove = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            approvalId: self.generalInfo.approvalId,
            contractId: self.contractId,
        };
        self[URI.MODULE_APPROVAL.CANCEL_APPROVAL.method](URI.MODULE_APPROVAL.CANCEL_APPROVAL, { url: urlParams, urltype: 'obj' })
            .then(function (result) {
                self.init();
                self.isModified = true;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    // --


    WorkflowEntityInstance.prototype.init2 = function (itemId) {
        var self = this;
        if (typeof itemId != 'undefined')
            this.moduleInstanceId = itemId;
        this.dataDefinition = null;
        this.dataOriginalObject = null;
        this.dataChangedObject = null;
        this.formObject = null;
        this.formDescription = null;
        this.formEntityFieldsLayout = null;
        this.loaded = false;
        this.workflow = {
            isStarted: false,
            transitionData: {
                comment: null
            },
            form: new Form(),
            transitions: [],
            transitionScreens: []
        };
        self.form.initializing = true;

        this.get_StateType()
            .then(function () {
                var promises = [self.get_startDataDefinition(), self.get_startData()];

                if (!self.workflow.isStarted) {
                    promises.push(self.get_startTransitions());
                } else {
                    // workflow started. 
                    // turning off edit mode, making workflow building calls
                    self.form.editMode = false;
                    promises.push(self.get_allTransitions());
                    promises.push(self.get_allTransitionScreens());

                    // creating the workflow form
                    self.workflow.form.set_Data(self.workflow.transitionData);
                    self.workflow.form.set_Description({
                        comment: { label: 'Comment', type: 'editor' }
                    });
                }

                var p_init = $q.all(promises);
                p_init
                    .then(function () {
                        self.parseFormDescription();
                        self.parseFormObject();

                        self.form.display = 'positiondata';
                        self.form.pattern = self.formEntityFieldsLayout.table;
                        self.form.set_Data(self.formObject);
                        self.form.set_Description(self.formDescription);
                    })
                    .catch(function (e) { })
                    .finally(function () { self.form.initializing = false; self.loaded = true; })
            })
            .catch(function () {
                self.form.initializing = false;
                self.loaded = true;
            });
    }

    WorkflowEntityInstance.prototype.transition = function (t) {
        var self = this;
        var t = t || null;

        if (self.workflow.isStarted) {
            return self.post_Transition(t);
        } else {
            return self.save_instanceObject(t);
        }
    }

    WorkflowEntityInstance.prototype.parseFormObject = function () {
        if (this.dataOriginalObject.fields && this.dataOriginalObject.fields.length) {
            this.formObject = {};
            var fields = this.dataOriginalObject.fields;
            for (var i = 0; i < fields.length; i++) {
                if (this.formDescription[fields[i].entityFieldId.toString()]
                    && this.formDescription[fields[i].entityFieldId.toString()].type == 'checkbox')
                    // set bools for checkboxes
                    this.formObject[fields[i].entityFieldId.toString()] =
                        fields[i].dataString === "True" ? true : false;
                else
                    // set empty strings to null for correct reference in angularjs form
                    this.formObject[fields[i].entityFieldId.toString()] =
                        fields[i].dataString !== "" ? fields[i].dataString : null;
            }
        }

        return;
    }

    WorkflowEntityInstance.prototype.buildChangedObject = function () {
        var self = this;

        if (self.dataOriginalObject.fields && self.dataOriginalObject.fields.length) {
            self.dataChangedObject = {
                fields: []
            };

            // get all properties of the original object except fields
            for (var key in self.dataOriginalObject) {
                if (self.dataOriginalObject.hasOwnProperty(key) && key != 'fields') {
                    self.dataChangedObject[key] = self.dataOriginalObject[key];
                }
            }

            // add contractId on Add
            if (!self.dataOriginalObject.contractId && !self.dataOriginalObject.moduleInstanceId)
                self.dataChangedObject.contractId = self.contractId;

            // parse new changed object fields values
            var fields = self.dataOriginalObject.fields;
            for (var i = 0; i < fields.length; i++) {
                self.dataChangedObject.fields.push(new function () {
                    var dataString = self.form.data[fields[i].entityFieldId.toString()] !== null ?
                        self.form.data[fields[i].entityFieldId.toString()] : '';

                    this.entityFieldId = fields[i].entityFieldId;
                    this.entityFieldInstanceId = fields[i].entityFieldInstanceId;
                    this.isChanged = fields[i].dataString != dataString;
                    this.dataString = dataString;
                });
            }
        }

        return;
    }

    WorkflowEntityInstance.prototype.parseFormDescription = function () {
        if (this.dataDefinition.fields && this.dataDefinition.fields.length) {
            this.formDescription = {};
            var fields = this.dataDefinition.fields;
            for (var i = 0; i < fields.length; i++) {
                this.formDescription[fields[i].entityFieldId.toString()] = {
                    label: fields[i].entityFieldName,
                    type: this.typeList[fields[i].entityFieldTypeId].type,
                }

                if (fields[i].isSystem)
                    this.formDescription[fields[i].entityFieldId.toString()].editMode = false;

                if (typeof fields[i].list != 'undefined') {
                    this.formDescription[fields[i].entityFieldId.toString()].options = [];
                    for (var key in fields[i].list) {
                        if (fields[i].list.hasOwnProperty(key)) {
                            this.formDescription[fields[i].entityFieldId.toString()].options.push({
                                key: key,
                                value: fields[i].list[key]
                            });
                        }
                    }
                }
            }

            try {
                this.formEntityFieldsLayout = new EntityFieldsLayout(this.dataDefinition.layouts[0].positionData);
            } catch { }
        }

        return;
    }

    WorkflowEntityInstance.prototype.get_startDataDefinition = function () {
        var self = this;
        var p = $q.defer();

        self[URI.MODULE_INSTANCE.START_LAYOUT.method](URI.MODULE_INSTANCE.START_LAYOUT, {}, { headers: { 'moduleId': self.moduleId } })
            .then(function (result) {
                self.dataDefinition = result;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_startData = function () {
        var self = this;
        var p = $q.defer();
        var param = self.moduleInstanceId ? { url: { moduleInstanceId: self.moduleInstanceId }, urltype: 'obj' } : {};

        self[URI.MODULE_INSTANCE.START_DATA.method](URI.MODULE_INSTANCE.START_DATA, param, { headers: { 'moduleId': self.moduleId } })
            .then(function (result) {
                self.dataOriginalObject = result;
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.assign_User = function (u) {
        var self = this;
        var p = $q.defer();
        self.isAssigningUser = true;
        self[URI.STATE_ASSIGNMENT.ASSIGN_USER.method](URI.STATE_ASSIGNMENT.ASSIGN_USER, { url: { entityInstanceId: self.entityInstanceId, contractId: self.contractId || 0, moduleId: self.moduleId }, urltype: 'obj', body: u })
            .then(function (result) {
                //self.dataOriginalObject = result;
                p.resolve(result);
                self.assignments.assignee = self.assignments.lookup[u.key];
                Message.info('User assignment changed');
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {

                        if (result[i].validFrom)
                            result[i].validFrom = userService.formatDateTime(result[i].validFrom);
                        if (result[i].validThrough)
                            result[i].validThrough = userService.formatDateTime(result[i].validThrough);
                    }
                }
                self.workflow.pastAssignments = result;

                self.get_activity_summary();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () { self.isAssigningUser = false; });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.checkRequiredFields = function (entityInstanceIds, transitionId) {
        var self = this;
        var p = $q.defer();

        var params = {
            url: {
                transitionId: transitionId,
                contractId: self.contractId
            },
            body: entityInstanceIds,
            urltype: 'obj'
        }

        self[URI.MODULE.CHECK_REQUIRED.method](URI.MODULE.CHECK_REQUIRED, params)
            .then(function (result) {
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.checkSignTransition = function (transitionId) {
        var self = this;
        var p = $q.defer();

        var params = {
            url: {
                transitionId: transitionId
            },
            urltype: 'obj'
        }

        self[URI.MODULE.CHECK_SIGN_TRANSITION.method](URI.MODULE.CHECK_SIGN_TRANSITION, params)
            .then(function (result) {
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_startTransitions = function () {
        var self = this;
        var p = $q.defer();

        self[URI.MODULE_INSTANCE.START_TRANSITIONS.method](URI.MODULE_INSTANCE.START_TRANSITIONS, {}, { headers: { 'moduleId': self.moduleId } })
            .then(function (result) {
                self.workflow.transitions = result;
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_allTransitions = function () {
        var self = this;
        var p = $q.defer();

        self[URI.MODULE_INSTANCE.ALL_TRANSITIONS.method](URI.MODULE_INSTANCE.ALL_TRANSITIONS,
            { url: { 'moduleInstanceId': self.moduleInstanceId }, urltype: 'obj' },
            { headers: { 'moduleId': self.moduleId } })
            .then(function (result) {
                self.workflow.transitions = result;
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_allTransitionScreens = function () {
        var self = this;
        var p = $q.defer();

        self[URI.MODULE_INSTANCE.ALL_TRANSITION_SCREENS.method](URI.MODULE_INSTANCE.ALL_TRANSITION_SCREENS,
            { url: { 'moduleInstanceId': self.moduleInstanceId }, urltype: 'obj' },
            { headers: { 'moduleId': self.moduleId } })
            .then(function (result) {
                self.workflow.transitionScreens = result;
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.get_StateType = function () {
        var self = this;
        var p = $q.defer();

        if (!self.moduleInstanceId) {
            p.resolve();
        } else {
            self[URI.MODULE_INSTANCE.STATE_IS_START.method](URI.MODULE_INSTANCE.STATE_IS_START,
                { url: { 'moduleInstanceId': self.moduleInstanceId }, urltype: 'obj' },
                { headers: { 'moduleId': self.moduleId } })
                .then(function (result) {
                    self.workflow.isStarted = !result;
                    p.resolve(result);
                })
                .catch(function (e) {
                    p.reject(e);
                });
        }

        return p.promise;
    }

    WorkflowEntityInstance.prototype.save_instanceObject = function (t) {
        var self = this;
        var p = $q.defer();
        self.buildChangedObject();
        self.dataChangedObject.transitionId = t.id;

        if (!self.moduleInstanceId) {
            self[URI.MODULE_INSTANCE.ADD.method](URI.MODULE_INSTANCE.ADD, self.dataChangedObject, { headers: { 'moduleId': self.moduleId } })
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    p.reject(e);
                });
        } else {
            self[URI.MODULE_INSTANCE.EDIT.method](URI.MODULE_INSTANCE.EDIT, self.dataChangedObject, { headers: { 'moduleId': self.moduleId } })
                .then(function (result) {
                    p.resolve(result);
                })
                .catch(function (e) {
                    p.reject(e);
                });
        }

        return p.promise;
    }

    WorkflowEntityInstance.prototype.post_Transition = function (t) {
        var self = this;
        var p = $q.defer();

        self[URI.MODULE_INSTANCE.TRANSITION.method](URI.MODULE_INSTANCE.TRANSITION,
            {
                transitionId: t.id,
                moduleInstanceId: self.moduleInstanceId,
                comment: self.workflow.transitionData.comment
            },
            { headers: { 'moduleId': self.moduleId } })
            .then(function (result) {
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });

        return p.promise;
    }

    WorkflowEntityInstance.prototype.undoLastAction = function (t) {
        var self = this;
        $mdDialog.show(confirm('Undo the last action', `The "${t.transitionName}" action by ${t.user} is about to be undone. Any comments, attachments, or signatures will be permanently deleted and cannot be restored. Are you sure?`, true))
            .then(function () {
                var dataURL = typeof URI[self.moduleCode.toUpperCase()] != "undefined" ? URI[self.moduleCode.toUpperCase()].ROLLBACK : URI.MODULE.ROLLBACK;
                var params = {
                    url: {
                        actionInstanceId: t.actionInstanceId,
                        contractId: self.contractId
                    },
                    urltype: "obj"
                };
                self[dataURL.method](dataURL, params, { headers: { moduleId: self.moduleId } })
                    .then(function () {
                        Message.info('The last action was undone successfully.');
                        self.init();
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                    });
            });
    }

    return WorkflowEntityInstance;
})
