import { cipo } from "cipo";

cipo.controller(
  "editFileDialogController",
  function (
    $scope,
    $mdDialog,
    file,
    entityInstanceId,
    rootModuleId,
    isDestinationFolder,
    userService
  ) {
    $scope.file = file ?? {};
    $scope.entityInstanceId = entityInstanceId ?? file.entityInstanceId;
    $scope.rootModuleId = rootModuleId;
    $scope.height = entityInstanceId ? 583 : 646;
    $scope.isDestinationFolder = isDestinationFolder;

    // get live operations
    Object.defineProperty($scope, "operations", {
      get: function () {
        return userService.getOperationsFor("FCM");
      },
    });

    $scope.canChangePermissions = $scope.operations["Permissions"] != undefined;

    $scope.onSave = function () {
      $mdDialog.hide(true);
    };

    $scope.onClose = function (status) {
      $mdDialog.cancel(status);
    };
  }
);
