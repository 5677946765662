import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services';
import { FileVersionCommentModel } from './attachments-note.model';

@Injectable()
export class AttachmentsNodeService {
  constructor(private http: HttpService) {}

  getAttachmentsNote(
    entityInstanceId: number,
    fieldId: number,
    parentEntityInstanceId?: number,
  ): Observable<FileVersionCommentModel[]> {
    const params = this.http.buildHttpParams({ entityInstanceId, fieldId, parentEntityInstanceId });
    return this.http.get<FileVersionCommentModel[]>(`_api/ModuleInstance/GetEntityInstanceFieldAttchments`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  saveAttachmentNote(note: FileVersionCommentModel, parentEntityInstanceId?: number): Observable<void> {
    const params = this.http.buildHttpParams({ parentEntityInstanceId });
    return this.http.put<void>(`_api/ModuleInstance/SaveEntityInstanceAttachmentNote`, note, {
      useLoader: false,
      httpOptions: { params },
    });
  }
}
