import { SearchViewModel } from '../../grid';

export interface AttachmentModel {
  id: number;
  name: string;
  version: number;
  versionId: number;
  mimeType?: MimeTypesEnum;
  mimeTypeId?: number;
  parentId: number;
  length?: number;
  order?: number;
  thumbnail50?: string;
  thumbnail200?: string;
  note?: string;
}

export enum MimeTypesEnum {
  PNG = 'image/png',
  PDF = 'application/pdf',
  TEXT_PLAIN = 'text/plain',
  JPEG = 'image/jpeg',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPTX2 = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  DOC = 'application/msword',
  XLS = 'application/vnd.ms-excel',
  PPT = 'application/vnd.ms-powerpoint',
  WOPI_TEST = 'application/wopitest',
  WOPI_TEST2 = 'application/wopitestx',
  ZIP = 'application/x-zip-compressed',
  EMAIL = 'message/rfc822',
}

export interface FileItemModel extends AttachmentModel {
  isShared: boolean;
  projectFilesName: string;
  myDriveName: string;
  setCompanyLogo: boolean;
  setUserLogo: boolean;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  isFolder: boolean;
  folderPath: string;
  parentName: string;
  isLocked: boolean;
  isDeleted: boolean;
  canDelete: boolean;
  folderPosition: number;
  parentsString: string;
  hasChildren: boolean;
  parents: number[];

  //   files
  thumbnail200: string;
  filePath: string;
}
export interface SearchFileModel {
  id: number;
  criteria?: string;
  search?: SearchViewModel;
  entityInstanceId?: number;
  isFolders?: boolean;
  isGlobal?: boolean;
  mimeTypes?: MimeTypesEnum[];
  isDeletedItems?: boolean;
}

export interface FileChunkParams {
  start: number;
  end: number;
  chunks: number;
  file: File;
}
