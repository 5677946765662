import { NgModule } from '@angular/core';
import { KeyValuePipe } from '../../shared/pipes/key-value.pipe';
import { ItvCurrencyPipe } from '../pipes/itv-currency.pipe';
import {
  ItvDatePipe,
  ItvDateTimeCustomFormatPipe,
  ItvDateTimePipe,
  ItvDateTimeWithRestrictionsPipe,
  ItvFromNowPipe,
  ItvTimePipe,
} from '../pipes/itv-date.pipe';
import { ItvNumberPipe, ItvNumberWithFormattingsPipe } from '../pipes/itv-number.pipe';
import { MeasurementPipe } from '../pipes/measurement/measurement.pipe';
import { ImageSourcePipe } from '../pipes/string-pipes/image-source.pipe';
import { ItvFileSizePipe } from '../pipes/file-size.pipe';

@NgModule({
  imports: [ImageSourcePipe],
  providers: [
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvCurrencyPipe,
    ItvDateTimeWithRestrictionsPipe,
    ItvDateTimeCustomFormatPipe,
    MeasurementPipe,
    ItvDatePipe,
    ItvTimePipe,
    ItvDateTimePipe,
    KeyValuePipe,
    ItvFileSizePipe,
  ],
  declarations: [
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvCurrencyPipe,
    ItvDateTimeWithRestrictionsPipe,
    ItvDateTimeCustomFormatPipe,
    MeasurementPipe,
    ItvDatePipe,
    ItvTimePipe,
    ItvFromNowPipe,
    ItvDateTimePipe,
    KeyValuePipe,
    ItvFileSizePipe,
  ],
  exports: [
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvCurrencyPipe,
    ItvDateTimeWithRestrictionsPipe,
    ItvDateTimeCustomFormatPipe,
    MeasurementPipe,
    ItvDatePipe,
    ItvTimePipe,
    ItvFromNowPipe,
    ItvDateTimePipe,
    KeyValuePipe,
    ImageSourcePipe,
    ItvFileSizePipe,
  ],
})
export class PipesModule {}
