import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { ListResponse } from '../../../../../models/helpers';
import { SearchFileModel, FileItemModel, FileChunkParams } from '../../../../../models/module/fields/main';
import { HttpService } from '../../../../../shared/services';
import { DisplayFormats, TableColumn } from '../../../../../shared/components/data-list/common/table.interface';
import { RestrictionType, RestrictionValueType } from '../../../../../models/module/fields/enums';
import { DriveTableAction, DriveTableActionsEnum, DriveTableRow } from '../models/data-list.models';
import { IFileService } from './file-factory.service';
import {
  AddChunkedVersionParams,
  CreateFolderParams,
  DuplicateVersionBody,
  DuplicateVersionParams,
  EditFolderParams,
  GetUploadUrlParams,
  GetVersionUrlParams,
  RenameFileParams,
  SaveFileInfoBody,
  SaveFileInfoParams,
  DeleteSharesParams,
} from '..';
import { FILE_SIZES } from 'src/app/shared/consts';
import { MyDriveFileModel } from '../../../../../models/files/mydrive-file.model';
import { ListParameter } from '../../../../../models/common';

@Injectable({ providedIn: 'root' })
export class MyDriveService implements IFileService<MyDriveFileModel> {
  httpService = inject(HttpService);
  translate = inject(TranslateService);

  getFileUrl(fileId: number, versionId: number, isPreview?: boolean) {
    const params = this.httpService.buildHttpParams({ fileId, versionId, ...(isPreview ? { isPreview } : {}) });
    return this.httpService.getText('_api/FolderFile/GetFileURL', { httpOptions: { params } });
  }

  getFilesUrl(versionIds: number[]) {
    return this.httpService.put<{ versionId: number; url: string }[]>('_api/FolderFile/GetFilesURL', {
      files: versionIds.map(versionId => ({ versionId })),
    });
  }

  getFile(fileId: number, parentId: number, entityInstanceId: number, versionId?: number): Observable<MyDriveFileModel> {
    const params = this.httpService.buildHttpParams({ fileId, parentId, entityInstanceId, versionId });
    return this.httpService.get<MyDriveFileModel>(`_api/FolderFile/GetFile`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  searchFiles(props: SearchFileModel) {
    return this.httpService
      .post<ListResponse<FileItemModel>>('_api/FolderFile/Search', props, { useLoader: false })
      .pipe(map(response => response.data));
  }

  getFileUploadUrl(body: GetUploadUrlParams) {
    const params = this.httpService.buildHttpParams({
      parentId: body.parentId,
      mimeType: body.mimeType,
      ...(body.isCreateVersion ? { isCreateVersion: body.isCreateVersion } : {}),
    });
    return '_api/FolderFile/UploadFile?' + params.toString();
  }

  getFileNewVersionUrl(body: GetVersionUrlParams) {
    const params = this.httpService.buildHttpParams({
      fileId: body.fileId,
      mimeType: body.mimeType,
    });
    return '_api/FolderFile/AddVersion?' + params.toString();
  }

  generateFileId() {
    return this.httpService
      .get<{ physicalFileId: string }>('_api/FolderFile/GenerateFileId', { useLoader: false })
      .pipe(map(response => response.physicalFileId));
  }

  uploadFileInChunks(fileName: string): Observable<void> {
    return this.httpService.get(`_api/FolderFile/UploadFileInChunks?fileName=${fileName}`, { useLoader: false });
  }

  uploadChunk(fileId: string, formData: FormData): Observable<void> {
    return this.httpService.post(`_api/FolderFile/UploadChunk?fileId=${fileId}`, formData, { useLoader: false });
  }

  saveFileInfo(params: SaveFileInfoParams, body: SaveFileInfoBody) {
    const httpParams = this.httpService.buildHttpParams(params);
    return this.httpService.post<FileItemModel>('_api/FolderFile/SaveFileInfo', body, {
      useLoader: false,
      httpOptions: { params: httpParams },
    });
  }

  addChunkedVersion(params: AddChunkedVersionParams, body: SaveFileInfoBody) {
    const httpParams = this.httpService.buildHttpParams(params);
    return this.httpService.post<FileItemModel>('_api/ProjectFolderFile/AddChunkedVersion', body, {
      useLoader: false,
      httpOptions: { params: httpParams },
    });
  }

  renameFile(body: RenameFileParams): Observable<void> {
    return this.httpService.put(`_api/FolderFile/RenameFile`, body, { useLoader: false });
  }

  createFolder(body: CreateFolderParams): Observable<void> {
    return this.httpService.post(`_api/FolderFile/CreateFolder`, body, { useLoader: false });
  }

  editFolder(body: EditFolderParams): Observable<void> {
    return this.httpService.put(`_api/FolderFile/EditFolder`, body, { useLoader: false });
  }

  downloadFileFromUrl(url: string) {
    try {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file';
      a.click();
      window.URL.revokeObjectURL(url);
    }
    catch (e) {
      console.error('Error downloading file from url', url, e);
    }
  }

  getChunkData({ file, start, end, chunks }: FileChunkParams) {
    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append('file', chunk, file.name);
    formData.append('chunk', Math.ceil(start / FILE_SIZES.chunkSize).toString());
    formData.append('chunks', chunks.toString());
    formData.append('fileName', file.name);
    return formData;
  }

  duplicateVersion(params: DuplicateVersionParams, body: DuplicateVersionBody) {
    const httpParams = this.httpService.buildHttpParams(params);
    return this.httpService.post<number>('_api/FolderFile/DuplicateVersion', body, {
      useLoader: false,
      httpOptions: { params: httpParams },
    });
  }

  deleteSharesGlobal(body: ListParameter, parentId: number): Observable<void> {
    return this.httpService.put(`_api/FolderFile/DeleteSharesGlobal?parentId=${parentId}`, body, { useLoader: false });
  }

  deleteShares(body: DeleteSharesParams): Observable<void> {
    return this.httpService.put(`_api/FolderFile/DeleteShares`, body, { useLoader: false });
  }

  saveFile(body: MyDriveFileModel, entityInstanceId?: number): Observable<void> {
    return this.httpService.put(`_api/FolderFile/SaveFile`, body, { useLoader: false });
  }

  getTableColumns(): TableColumn[] {
    return [
      {
        id: 0,
        name: 'image',
        displayName: '',
        priority: 3,
        sortable: false,
        width: 0.5,
        align: 'center',
        displayFormatId: DisplayFormats.Image,
      },
      {
        id: 1,
        name: 'name',
        displayName: this.translate.instant('fileExplorer.name'),
        priority: 3,
        sortable: true,
        width: 3,
      },
      {
        id: 2,
        name: 'createdBy',
        displayName: this.translate.instant('fileExplorer.createdBy'),
        priority: 3,
        sortable: true,
        width: 1,
      },
      {
        id: 3,
        name: 'createdOn',
        displayName: this.translate.instant('fileExplorer.createdOn'),
        priority: 2,
        sortable: true,
        displayFormatId: DisplayFormats.Date,
        restrictions: [
          {
            key: RestrictionType.DateTime,
            value: RestrictionValueType.Date,
            mimeTypes: [],
            mimeTypesDataSource: [],
          },
        ],
        width: 1,
      },
      {
        id: 4,
        name: 'updatedOn',
        displayName: this.translate.instant('fileExplorer.updatedOn'),
        priority: 0,
        sortable: true,
        displayFormatId: DisplayFormats.Date,
        restrictions: [
          {
            key: RestrictionType.DateTime,
            value: RestrictionValueType.Date,
            mimeTypes: [],
            mimeTypesDataSource: [],
          },
        ],
        width: 1,
      },
      {
        id: 5,
        name: 'length',
        displayName: this.translate.instant('fileExplorer.length'),
        priority: 1,
        width: 1,
        sortable: true,
      },
    ];
  }

  getTableActions(): DriveTableAction[] {
    return [
      {
        id: DriveTableActionsEnum.VIEW,
        displayName: this.translate.instant('fileExplorer.view'),
        rightIcon: 'start',
        condition: (row: DriveTableRow) => row.isFolder,
      },
      {
        id: DriveTableActionsEnum.OPEN_TAB,
        displayName: this.translate.instant('cipoFields.openNewTab'),
        rightIcon: 'open_in_new',
        condition: (row: DriveTableRow) => !row.isFolder,
      },
      {
        id: DriveTableActionsEnum.RENAME,
        displayName: this.translate.instant('fileExplorer.rename'),
        rightIcon: 'edit',
        condition: (row: DriveTableRow) => !row.isLocked,
      },
    ];
  }
}
