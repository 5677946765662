import { cipo } from 'cipo';
import { ScreenTypeEnum } from '/src/app/models/module/screen';
import { UserService } from 'src/app/shared/services/user.service';
import { FROALA_TOOLBAR_BIG } from 'src/app/shared/components/froala/common';

cipo.factory("ModuleN", function ($q, Model, URI, Message, PresetMod, ActionTypes,
    StateMod, SignatureMod, TransitionGroupMod, RelationMod, PaymentRelationMod,
    WorkflowMod, Form, Screen, FormConfig, TemplateMod, EntityFieldsLayout, WorkflowGroupMod,
    DataListConfig, Dictionaries, ModuleDictionaries, TEMPLATES_TYPES, GenericClass, ActionMod,
    $mdDialog, userService) {
    var ModuleN = Model.extend(function (obj, isRelation, isSubmodule) {
        var self = this;
        self.isGStateClosed = false;
        self.loading = true;
        self.disabled = obj ? obj.disabled : false;
        self.properties = {};
        self.anyStateRemovedApproval = false;
        self.templatesChanged = false;
        self.mainScreenModified = false;

        if (!isRelation && !isSubmodule) {
            self.set_properties(obj);

            if (!self.properties.isSystem) {
                self.properties.documentNumberTemplate = (obj || {}).documentNumberTemplate || "{{abbreviation}}-{{increment,3}}";
            }

            if (self.properties.id) {
                self.init();
                self.get_data();
            } else {
                self.createForm();
            }
        }
        else if (isRelation) {
            self.properties.id = obj.moduleId;
            self.relationId = obj.relationId;
            self.get_DataListSettings(obj.moduleId, self.relationId);
        }
        else {
            self.properties.id = obj.id;
            self.properties.relationId = obj.relationId;
            self.properties.name = obj.name;
            self.properties.perContract = true;
            self.isSubmodule = true;
            self.init();
        }
    });

    ModuleN.prototype.set_properties = function (obj) {
        var self = this;
        self.properties = {
            id: null,
            abbreviation: "",
            disabled: false,
            iconId: null,
            isSystem: false,
            isUrl: false,
            url: null,
            name: "",
            description: "",
            information: "",
            perContract: true,
            projectFolderId: null,
            hasTemplates: true,
            hasWorkflow: true,
            hasFields: true,
            hasRelations: true,
            hasInformation: true,
            color: "",
            revisionBase: 0,
            agencyResponseDays: 0,
            documentNumberStartAutoincrement: 1,
            showOnDashboard: false,
            useExclude: true,
            documentNumberMinorMax: 0,
            descriptionFieldId: null,
            linkDefinitionId: 0,
            isLinkedToCorrespondence: false,
            prefixFieldProjectFolder: [],
            prefixFieldId: null,
            suffixFieldId: null,
            usePrefix: true,
            canManageWorkflowGroups: false,
            communicationInSameThread: true,
            allowContactsNotifications: false,
            useDocMinor: userService.system.modules['M']?.useDocMinor || false
        };

        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            if (self.properties.isSystem) {
                self.properties.hasTemplates = false;
                self.properties.hasWorkflow = false;
                self.properties.hasRelations = false;
                self.properties.hasFields = false;
            }
        }

        if (self.form) {
            self.form.set_Data(self.properties);
            self.form.isValid = true;
        }
    }

    ModuleN.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        // if (self.properties.id) self.form.loading = true;
        var perContract = [{ key: true, value: 'Yes' }, { key: false, value: 'No' }];
        var useMinor = self.properties.useDocMinor;
        //create form
        var formForNonEntity = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
            abbreviation: { label: 'Abbr.', type: 'text', validation: { required: true, maxChars: 15 } },
            description: { label: 'Description', type: 'textarea', validation: { maxChars: 500 } },
            perContract: { label: 'Per contract', type: 'select', options: perContract, optionsHasEmptyValue: false },
            showOnDashboard: { label: 'Show on Dashboard', type: 'checkbox', labelOnTop: true, visible: true },
            useExclude: { label: 'Hide Status from Excluded Users', type: 'checkbox', labelOnTop: true, visible: true },
            descriptionFieldId: { label: 'Description Reference Field', type: 'select', options: [] },
            color: { label: 'Icon color', type: 'colorpicker' },
            documentNumberStartAutoincrement: { label: 'Document No Start', type: 'number', validation: { isInteger: true, greaterThan: 0 } },
            documentNumberMinorMax: { label: 'Document No Minor Max', type: 'number', validation: { isInteger: true, greaterThan: 0 }, visible: useMinor },
            revisionBase: { label: 'Revision No Start', type: 'number', validation: { isInteger: true, greaterThan: -1 } },
            agencyResponseDays: { label: 'Response Days', type: 'number', validation: { isInteger: true, greaterThan: -1 } },
            allowContactsNotifications: { label: 'Allow notifications to contacts', type: 'checkbox', labelOnTop: true, visible: true },
        };

        var formForDM = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 }, x: 0, y: 0, rows: 1, cols: 4 },
            abbreviation: { label: 'Abbr.', type: 'text', validation: { required: true, maxChars: 15 }, x: 4, y: 0, rows: 1, cols: 1 },
            perContract: { label: 'Per contract', type: 'select', options: perContract, optionsHasEmptyValue: false, x: 5, y: 0, rows: 1, cols: 1 },
            showOnDashboard: { label: 'Show on Dashboard', type: 'checkbox', labelOnTop: true, visible: true, x: 6, y: 0, rows: 1, cols: 2 },
            useExclude: { label: 'Hide Status from Excluded Users', type: 'checkbox', labelOnTop: true, visible: true, x: 8, y: 0, rows: 1, cols: 2 },
            allowContactsNotifications: { label: 'Allow notifications to contacts', type: 'checkbox', labelOnTop: true, visible: true, x: 10, y: 0, rows: 1, cols: 2 },
            information: {
                label: 'Information', type: 'editor', x: 6, y: 1, rows: 3, cols: 6,
                restrictions: [{ key: 8, restrictionValue: 500 }],
                options: {
                    toolbarButtons: FROALA_TOOLBAR_BIG.toolbarButtons,
                    toolbarButtonsXS: FROALA_TOOLBAR_BIG.toolbarButtonsXS,
                }
            },

            description: { label: 'Description', type: 'textarea', validation: { maxChars: 500 }, x: 0, y: 1, rows: 1, cols: 3 },
            color: { label: 'Icon color', type: 'colorpicker', x: 3, y: 1, rows: 1, cols: 3 },

            documentNumberStartAutoincrement: { label: 'Document No Start', type: 'number', validation: { isInteger: true, greaterThan: 0 }, x: 0, y: 2, rows: 1, cols: useMinor ? 2 : 3 },
            documentNumberMinorMax: { label: 'Document No Minor Max', type: 'number', validation: { isInteger: true, greaterThan: 0 }, visible: useMinor, x: 2, y: 2, rows: 1, cols: 2 },
            revisionBase: { label: 'Revision No Start', type: 'number', validation: { isInteger: true, greaterThan: -1 }, x: useMinor ? 4 : 2, y: 2, rows: 1, cols: useMinor ? 2 : 3 },

            agencyResponseDays: { label: 'Response Days', type: 'number', validation: { isInteger: true, greaterThan: -1 }, x: 0, y: 3, rows: 1, cols: 3 },
            descriptionFieldId: { label: 'Description Reference Field', type: 'select', options: [], x: 3, y: 3, rows: 1, cols: 3 },
        };

        var form = self.properties.hasInformation ? formForDM : formForNonEntity;

        if (self.properties.isSystem) form.abbreviation.editMode = false;
        if (self.properties.id) form.perContract.editMode = false;

        self.form.set_Description(form);

        var grid1 = useMinor
            ? [
                { name: 32, abbreviation: 13, perContract: 13, showOnDashboard: 14, useExclude: 14, allowContactsNotifications: 14 },
                { description: 75, color: 25 },
                { documentNumberStartAutoincrement: 33, documentNumberMinorMax: 33, revisionBase: 33 },
                { agencyResponseDays: 33, descriptionFieldId: 66 }
            ]
            : [
                { name: 32, abbreviation: 13, perContract: 13, showOnDashboard: 14, useExclude: 14, allowContactsNotifications: 14 },
                { description: 75, color: 25 },
                { documentNumberStartAutoincrement: 50, revisionBase: 50 },
                { agencyResponseDays: 33, descriptionFieldId: 66 }
            ];

        if (self.properties.hasInformation) {
            var tableForm = [];
            for (var key in form)
                if (form.hasOwnProperty(key)) tableForm.push(form[key]);
            self.form.display = 'positiondata';
            self.form.pattern = (new EntityFieldsLayout(tableForm)).table;
        } else {
            self.form.setTemplate('grid', grid1);
        }

        // 
        self.form.store_Data();

        self.form.initializing = false;
    }

    ModuleN.prototype.setTemplate = function (i) {
        var self = this;
        var useMinor = self.properties.useDocMinor;
        var grid1 = useMinor
            ? [
                { name: 32, abbreviation: 13, perContract: 13, showOnDashboard: 14, useExclude: 14, allowContactsNotifications: 14 },
                { description: 75, color: 25 },
                { agencyResponseDays: 25, documentNumberStartAutoincrement: 25, documentNumberMinorMax: 25, revisionBase: 25 }
            ]
            : [
                { name: 32, abbreviation: 13, perContract: 13, showOnDashboard: 14, useExclude: 14, allowContactsNotifications: 14 },
                { description: 75, color: 25 },
                { agencyResponseDays: 33, documentNumberStartAutoincrement: 33, revisionBase: 33 }
            ];
        var grid2 = useMinor
            ? [
                { name: 32, abbreviation: 13, perContract: 13, showOnDashboard: 14, useExclude: 14, allowContactsNotifications: 14 },
                { description: 50, color: 50 },
                { agencyResponseDays: 25, documentNumberStartAutoincrement: 25, documentNumberMinorMax: 25, revisionBase: 25 }
            ]
            : [
                { name: 32, abbreviation: 13, perContract: 13, showOnDashboard: 14, useExclude: 14, allowContactsNotifications: 14 },
                { description: 50, color: 50 },
                { agencyResponseDays: 33, documentNumberStartAutoincrement: 33, revisionBase: 33 }
            ];

        if (i == 1) {
            self.form.setTemplate('grid', grid1);
        } else {
            self.form.setTemplate('grid', grid2);
        }
    }

    ModuleN.prototype.init = function (isNew) {
        var self = this;
        // self.loadingGrid = true;
        self.currentType = {};
        self.rolesLookup = {};
        // dictionaries
        self.operationsDict = [];
        self.assignedRolesDict = [];
        self.screensDict = [];
        self.startScreensDict = [];
        self.formsDict = [];
        self.mainFormsDict = [];
        self.notificationsDict = [];
        self.stateTypesDict = [];
        self.statesDict = [];
        self.lookupStates = {};
        self.signatureDict = [];
        self.headersDict = [];
        self.footersDict = [];
        self.relationTypesDict = [];
        self.signatureRolesDict = [];
        self.closedStatesDict = [];
        self.assignmentsDict = [];

        // lists
        self.permissionsList = [];
        self.statesList = [];
        self.screensList = [];
        self.fieldsList = [];
        self.formsList = [];
        self.startscreensList = [];
        self.templatesList = [];
        self.templatesGroupList = [];
        self.notificationList = [];
        self.fieldsDictSource = [];
        self.relationsList = [];
        self.transitionGroupsList = [];
        self.signaturesList = [];
        self.presetsList = [];

        self.backupRolesList = [];
        self.rolesList = [];

        Object.defineProperty(self, 'dirtyPermissions', {
            get: function () { return !angular.equals(self.extractPermissions(), self.backupRolesList); }
        });

        if (isNew) {
            self.isBusy = true;
            var all = $q.all([
                Dictionaries.ModulePropertyFields({}, { moduleId: self.properties.id }),
                Dictionaries.ModuleTypes(self.properties.id)
            ]);
            all.then(function (r) {
                self.form.set_Description({
                    descriptionFieldId: { label: 'Description Reference Field', type: 'select', options: r[0], info: "If empty, the Full Document No will be used" },
                }, true);

                self.moduleTypes = r[1];
                self.currentType = r[1][0];
                ModuleDictionaries.init({ moduleId: self.properties.id, currentType: self.currentType });

            }).catch(function () {
            }).finally(function () {
                self.isBusy = false;
            })
        }
    }

    var OjectLayer = function () {
        this.isShow = false;
        this.list = [];
    }

    ModuleN.prototype.get_projectFolderDetails = function () {
        var self = this;
        var dataURL = URI.PROJECT_FILE_MANAGER.GET_FOLDER;
        var p = $q.defer();
        self.loadingDestinationFolder = true;
        self[dataURL.method](dataURL, { url: { folderId: self.properties.projectFolderId, isFolders: true, onlyEssentialInformation: true }, urltype: 'obj' })
            .then(function (r) {
                p.resolve();
                self.destinationFolder = r;
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                console.error(e);
            }).finally(function () { self.loadingDestinationFolder = false; })
    }

    ModuleN.prototype.isCorrespondenceReadPermission = function (permission) {
        return this.properties.id == -11/* correspondence module */ && permission.id == 2/* read permission */;
    }

    ModuleN.prototype.get_data = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;

        var getDetails = function () {
            var p1 = $q.defer();
            if (!self.form) self.form = new Form();
            (self.form || {}).loading = true;
            self[URI.MODULES.GET.method](URI.MODULES.GET + '?id=' + self.properties.id)
                .then(function (result) {
                    for (var key in result) {
                        if (result.hasOwnProperty(key)) {
                            self.properties[key] = result[key];
                        }
                    }

                    // Set if module accept to create link with correspondence
                    self.properties.allowLinkCorrespondence = self.properties.hasWorkflow && self.properties.typeId == '3';

                    if (self.properties.documentNumberTemplate) self.get_documentNumberPreview();
                    if (self.properties.projectFolderId) self.get_projectFolderDetails();
                    self.createForm();
                    self.form.set_Data(self.properties);
                    if (self.properties.isSystem) self.form.fieldsList.abbreviation.editMode = false;
                    self.getDestionationFolderFields();
                    if (self.properties.suffixFieldId) self.properties.usePrefix = false;
                    self.form.fieldsList.perContract.editMode = false;
                    self.form.store_Data();
                    p1.resolve();
                }).catch(function (e) {
                    p1.reject(e);
                    Message.dberror(e);
                    console.error(e);
                }).finally(function () {
                    self.form.loading = false;
                });
            return p1.promise;
        }

        var all = $q.all([
            Dictionaries.ModulePropertyFields({}, { moduleId: self.properties.id }),
            Dictionaries.ModuleTypes(self.properties.id),
            getDetails()
        ]);
        // self.form.loading = true;

        all.then(function (r) {

            self.form.set_Description({
                descriptionFieldId: { label: 'Description Reference Field', type: 'select', options: r[0], info: "If empty, the Full Document No will be used" },
            }, true);

            self.moduleTypes = r[1];
            self.currentType = r[1][0];
            ModuleDictionaries.init({ moduleId: self.properties.id, currentType: self.currentType });

        }).catch(function () {

        }).finally(function () {
            self.isBusy = false;
        })


        return p.promise;
    }

    ModuleN.prototype.getDestionationFolderFields = function () {
        var self = this;
        var dataURL = URI.FIELD_DEFINITION.DESTINATION_FOLDER_DICT;
        self[dataURL.method](dataURL, { url: {} }, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                self.properties.prefixFieldProjectFolder = r;
                self.form.set_Data(self.properties);
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
    }

    ModuleN.prototype.syncDestinationFolder = function () {
        var self = this;
        var dataURL = URI.MODULES.SYNC_PDFS;
        self.isSyncing = true;
        self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info("Sync started. You will receive an email notification when the process is done.");
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () { self.isSyncing = false; })
    }

    ModuleN.prototype.syncCorrespondence = function () {
        var self = this;

        if (self.properties.linkDefinitionId) {
            var urlData = URI.MODULE_LINK_CORRESPONDENCE.GET;
            Model[urlData.method](urlData, {}, { headers: { moduleId: self.properties.id } })
                .then(function (r) {
                    self.showLinkDialog(self.properties.id, self.properties.isLinkedToCorrespondence, r);
                })
                .catch(function (e) { Message.dberror(e); })
        }
        else {
            self.showLinkDialog(self.properties.id, self.properties.isLinkedToCorrespondence);
        }
    }

    ModuleN.prototype.manageCategories = function () {
        $mdDialog.show({
            locals: { },
            controller: 'editSovCategoriesController',
            templateUrl: '/ng/views/admin/modals/editSovCategories.html',
            parent: angular.element(document.body),
            fullscreen: true,
            escapeToClose: false,
            clickOutsideToClose: false,
            multiple: true,
            focusOnOpen: false,
        })
        .then(function () {
            // click
        }, function () {

        });
    }

    ModuleN.prototype.showLinkDialog = function (moduleId, isLinkedToCorrespondence, definition) {
        var self = this;

        $mdDialog.show({
            locals: { moduleId: moduleId, isLinkedToCorrespondence: isLinkedToCorrespondence, linkDefinition: definition },
            controller: 'linkToCorrespondenceController',
            templateUrl: '/ng/controllers/module/linkToCorrespondence.html',
            parent: angular.element(document.body),
            fullscreen: true,
            focusOnOpen: false,
            multiple: true,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function () {

                self.form.loading = true;

                // Reload data
                self.get_data();

            }, function () {
                // cancel pressed
            })
            .finally(function () {
                self.form.loading = false;
            });
    }

    var confirm = function (title, text, isMultiple) {
        return $mdDialog.confirm({
            title: title,
            textContent: text,
            ariaLabel: 'Confirm Dialogue',
            ok: 'Proceed',
            cancel: 'Cancel',
            multiple: isMultiple || false
        });
    }

    ModuleN.prototype.removeMapping = function () {
        var self = this;

        $mdDialog.show(confirm('Remove Mapping', `Are you sure you want to remove mapping with E-mail ?`, true))
            .then(function () {

                var urlData = URI.MODULE_LINK_CORRESPONDENCE.DELETE;
                Model[urlData.method](urlData, { url: { id: self.properties.linkDefinitionId }, urltype: 'obj' }, { headers: { moduleId: self.properties.id } })
                    .then(function (r) {
                        self.form.loading = true;

                        // Reload data
                        self.get_data();

                        Message.info('Link with E-mail removed successfully.');
                    })
                    .catch(function (e) { Message.dberror(e); })
                    .finally(function () {
                        self.form.loading = false;
                    })
            });
    };

    ModuleN.prototype.update_documentNumber = function () {
        var self = this;
        self.docNoForm.store_Data();
        self.properties.documentNumberTemplate = self.docNo.template.replace(/<(.|\n)*?>/g, '').replace(/\u2060/g, '').replace(/&nbsp;/g, " ");
        self.get_documentNumberPreview();
    }

    ModuleN.prototype.set_documentNumber = function () {
        var self = this;
        var p = $q.defer();
        self.docNo = { template: self.properties.documentNumberTemplate };
        self.docNoForm = new Form(self.docNo);
        self.docNoForm.initializing = true;
        self.docNoForm.loading = true;
        var dataURL = URI.MODULES.DOC_NUM_PLACEHOLDERS;

        self[dataURL.method](dataURL)
            .then(function (result) {

                self.docNoForm.set_Description({
                    template: { label: 'Document Number Template', type: 'editor', hints: result }
                });
                self.docNoForm.setTemplate('grid', [
                    { template: 100 }
                ]);
                self.docNoForm.store_Data();
                p.resolve();
            }).catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                console.error(e);
            }).finally(function () {
                self.docNoForm.initializing = false;
                self.docNoForm.loading = false;
            });

        return p.promise;
    }

    ModuleN.prototype.get_documentNumberPreview = function () {
        var self = this;
        var p = $q.defer();
        self.previewDocNo = "";
        self.loadingPreview = true;
        var dataURL = URI.MODULES.DOC_NUM_MOCK_PREVIEW;
        self[dataURL.method](dataURL, { url: {}, body: self.properties })
            .then(function (result) {
                self.previewDocNo = result;
                p.resolve();
            }).catch(function (e) {
                p.reject(e);
                console.error(e);
                Message.dberror(e);
            }).finally(function () {
                self.loadingPreview = false;
            });

        return p.promise;
    }

    ModuleN.prototype.initialize = function () {
        var self = this;
    }

    ModuleN.prototype.get_WorkflowGroups = function () {
        var self = this;
        var p = $q.defer();
        self.isWorkflowGroupsLoaded = false;
        self.workflowGroups.isShow = true;
        self.workflowGroups.isBusy = true;
        var dataURL = URI.WORKFLOW_GROUPS.SEARCH;
        self[dataURL.method](dataURL, { url: {}, urltype: 'obj' }, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                if (((r || {}).data || []).length) {
                    for (var i = 0; i < r.data.length; i++) {
                        r.data[i] = new WorkflowGroupMod(r.data[i], self.properties.id);
                    }
                }
                self.workflowGroups.list = r.data;
                p.resolve();
            }).catch(function (e) {
                p.reject(e);
                console.error(e);
                Message.dberror(e);
            }).finally(function () {
                self.isWorkflowGroupsLoaded = true;
                self.workflowGroups.isBusy = false;
            });

        return p.promise;
    }

    ModuleN.prototype.workflowGroup_init = function () {
        var self = this;
        self.isBusy = true;
        self.workflowGroups = new OjectLayer();
        self.get_WorkflowGroups();

        ModuleN.prototype.new_WorkflowGroup = function () {
            var self = this;
            return new WorkflowGroupMod(null, self.properties.id);
        }
    }

    ModuleN.prototype.actions_init = function () {
        var self = this;
        self.isBusy = true;
        self.actions = new OjectLayer();
        ModuleDictionaries.getDicts(["allRoles", "forms", 'actionFields']);

        self.get_Actions();
    }

    ModuleN.prototype.new_Action = function (type) {

        var self = this;

        var action;
        if (type == ActionTypes['Assignment']) {

            // In case we have assignment, and already have an assignment created, load that one
            // Check if assignment already exists
            if (self.actions && self.actions.list && self.actions.list.length > 0) {

                // We have some actions, get Assignment action from the list
                var _action = self.actions.list.filter(function (item) {
                    return item.properties.type == type;
                });

                // Check if action exists
                if (_action && _action.length > 0 && _action[0].properties)
                    action = new ActionMod(_action[0].properties, true);
                else
                    action = new ActionMod({ type: type });
            }
            // We don't have an assignment, so just create a new one
            else
                action = new ActionMod({ type: type });
        }
        else
            action = new ActionMod({ type: type });

        return action;
    }

    ModuleN.prototype.get_Actions = function () {
        var self = this;
        self.actions.isShow = true;
        self.actions.isBusy = true;
        var p = $q.defer();
        self.isActionsLoaded = false;
        var dataURL = URI.ACTIONS.SEARCH;
        self[dataURL.method](dataURL, { url: { moduleTypeId: self.currentType.key }, urltype: 'obj' })
            .then(function (r) {
                if (((r || {}).data || []).length) {
                    for (var i = 0; i < r.data.length; i++) {
                        r.data[i] = new ActionMod(r.data[i]);
                    }
                }
                self.actions.list = r.data;
                p.resolve();
            }).catch(function (e) {
                p.reject(e);
                console.error(e);
                Message.dberror(e);
            }).finally(function () {
                self.isActionsLoaded = true;
                self.actions.isBusy = false;
            });

        return p.promise;
    }

    ModuleN.prototype.action_Action = function (action, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting action..." : "Saving action...";
        var action = isDelete ? action.delete() : action.save();
        action
            .then(function () {
                self.get_Actions();
                // screen.editable = false;
                delete action.form;

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ModuleN.prototype.workflowGroup_Action = function (group, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting workflow group..." : "Saving workflow group...";
        var group = isDelete ? group.delete() : group.save();
        group
            .then(function () {
                self.get_WorkflowGroups();
                delete group.form;

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    // ????
    ModuleN.prototype.get_signaturesDicts = function (workflowId) {
        var self = this;
        var p = $q.defer();
        self.signatureRolesDict = [];
        var dicts = $q.all([Dictionaries.AllRoles({ moduleTypeId: self.currentType.key }),
        Dictionaries.ClosedStates({ moduleId: self.properties.id }, { workflowId: workflowId })])
        dicts
            .then(function (r) {
                if (r[0] && r[0].length) {
                    for (var i = 0; i < r[0].length; i++) {
                        if (r[0][i].isUsed) self.signatureRolesDict.push(r[0][i]);
                    }
                }
                self.closedStatesDict = r[1];
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            })
            .finally(function () {
                self.isSignatureDictsLoaded = true;
            })
        return p.promise;
    }

    // relations
    ModuleN.prototype.relations_init = function () {
        var self = this;

        ModuleDictionaries.getDicts(["relationTypes", "modules", "moduleTypes"])
            .then(function () { self.get_RelationsList(); })
    }

    ModuleN.prototype.get_RelationsList = function (moduleType) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self.isRelationsLoaded = false;
        if (moduleType || self.relationsModuleTypeKey != self.currentType.key) self.relationsList = [];
        self.currentType = moduleType || (self.currentType || self.moduleTypes[0]);
        self.relationsModuleTypeKey = self.currentType.key;
        var dataURL = URI.MODULE_RELATION.SEARCH;

        self[dataURL.method](dataURL, { url: { moduleTypeId: self.currentType.key }, urltype: "obj" },
            { headers: { moduleId: self.properties.id } })
            .then(function (result) {
                self.relationsList = [];
                if (result && result.data && result.data.length) {
                    var relation;
                    for (var i = 0; i < result.data.length; i++) {
                        if (result.data[i].relationType == 1) {
                            relation = new RelationMod(result.data[i]);
                            // relation.setDicts(self.relationTypesDict, self.modulesDict, self.moduleTypes);
                        } else {
                            relation = new PaymentRelationMod(result.data[i]);
                            // relation.setDicts(self.modulesDict, self.moduleTypes);
                        }


                        self.relationsList.push(relation);

                    }
                }
                self.isRelationsLoaded = true;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    ModuleN.prototype.new_Relation = function (type) {
        var self = this;
        var relation;
        if (type == 1) {
            relation = new RelationMod({ parentTypeId: self.currentType.key });
            // relation.setDicts(self.relationTypesDict, self.modulesDict, self.moduleTypes);
        } else if (type == 2) {
            relation = new PaymentRelationMod();
            // relation.setDicts(self.modulesDict, self.moduleTypes);
        }

        return relation;
    }
    ModuleN.prototype.relation_Action = function (relation, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting relation..." : "Saving relation...";
        var action = isDelete ? relation.delete() : relation.save();
        action
            .then(function () {
                self.get_RelationsList();
                // screen.editable = false;
                delete relation.form;

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }


    // presets

    ModuleN.prototype.presets_init = function () {
        var self = this;
        var p = $q.defer();
        var calls = $q.all([ModuleDictionaries.getDicts(["contractTypes", "availableDocNumbers", "startScreens"])]);

        calls
            .then(function () {
                self.get_PresetsList();
                p.resolve();
            }).catch(function () { p.reject() })

        self.presetsTypes = new OjectLayer();

        return p.promise;

    }

    ModuleN.prototype.get_PresetsList = function (moduleType) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self.isPresetsLoaded = false;
        //if (moduleType || self.relationsModuleTypeKey != self.currentType.key) self.relationsList = [];
        //self.currentType = moduleType || (self.currentType || self.moduleTypes[0]);
        //self.relationsModuleTypeKey = self.currentType.key;
        var dataURL = URI.MODULE_PRESETS.SEARCH;

        self[dataURL.method](dataURL, { url: {}, urltype: "obj" },
            { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                self.presetsList = [];
                if (r && r.data && r.data.length) {
                    for (var i = 0; i < r.data.length; i++) {
                        r.data[i] = new PresetMod(r.data[i]);
                    }
                    self.presetsList = r.data;

                }
                self.isPresetsLoaded = true;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    ModuleN.prototype.presetsTypes_init = function () {
        var self = this;
        GenericClass.prototype.formFields = function () {
            return {
                name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 } },
                description: { label: 'Description', type: 'textarea', validation: { required: true, maxChars: 255 } },
            }
        };
        GenericClass.prototype.formPattern = function () {
            return [
                { name: 50, description: 50 }
            ];
        };

        GenericClass.prototype.urls = {
            create: function () { return URI.MODULE_PRESETS_TYPES.CREATE },
            edit: function () { return URI.MODULE_PRESETS_TYPES.UPDATE },
            delete: function () { return URI.MODULE_PRESETS_TYPES.DELETE }
        };
        self.presetsTypes.isShow = true;
        self.isBusy = true;
        if (!self.isPresetsTypesLoaded) self.get_PresetsTypesList();
    }

    ModuleN.prototype.presetsTypes_cancel = function () {
        var self = this;
        self.presetsTypes.isShow = false;
        self.isBusy = false;
    }

    ModuleN.prototype.get_PresetsTypesList = function () {
        var self = this;
        var p = $q.defer();
        // self.isBusy = true;
        self.isPresetsTypesLoaded = false;
        var dataURL = URI.MODULE_PRESETS_TYPES.SEARCH;

        self[dataURL.method](dataURL, { url: {}, urltype: "obj" },
            { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                self.presetsTypes.list = [];
                if (r && r.data && r.data.length) {
                    for (var i = 0; i < r.data.length; i++) {
                        r.data[i] = new GenericClass(r.data[i], {}, { moduleId: self.properties.id });
                    }
                    self.presetsTypes.list = r.data || [];
                }
                self.isPresetsTypesLoaded = true;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                // self.isBusy = false;
            })

        return p.promise;
    }

    ModuleN.prototype.new_Preset = function () {
        var self = this;
        return new PresetMod();
    }

    ModuleN.prototype.new_PresetType = function () {
        return new GenericClass({ name: "", description: "" }, {}, { moduleId: this.properties.id });
    }
    ModuleN.prototype.preset_Action = function (preset, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting preset..." : "Saving preset...";
        var action = isDelete ? preset.delete() : preset.save();
        action
            .then(function () {
                self.get_PresetsList();
                // screen.editable = false;
                delete preset.form;

                self.isModified = true;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ModuleN.prototype.presetsTypes_Action = function (preset, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting preset..." : "Saving preset...";
        var action = isDelete ? preset.delete() : preset.save();
        action
            .then(function () {
                self.get_PresetsTypesList();
                ModuleDictionaries.get_dataSource("presetTypes");
                // screen.editable = false;
                delete preset.form;

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }
    // transitionsGroup

    ModuleN.prototype.transitionGroups_init = function () {
        var self = this;
        ModuleDictionaries.addUrlParameter({ workflowId: self.workflowTransitionGroup.workflowId });
        ModuleDictionaries.refreshDicts(["signatureTransitions"])
            .then(function () { self.get_transitionGroupsList(); })
    }

    ModuleN.prototype.get_transitionGroupsList = function () {
        var self = this;
        var p = $q.defer();
        // self.transitionGroupsList = [];
        self.isTransitionGroupsLoaded = false;
        self.isBusy = true;
        var dataURL = URI.TRANSITION_GROUP.SEARCH;
        self[dataURL.method](dataURL, { url: { workflowId: self.workflowTransitionGroup.workflowId }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
            .then(function (result) {
                self.transitionGroupsList = [];
                if (result && result.data && result.data.length) {
                    for (var i = 0; i < result.data.length; i++) {
                        var transitionGroup = new TransitionGroupMod(result.data[i], self.workflowTransitionGroup.workflowId);
                        self.transitionGroupsList.push(transitionGroup);
                    }
                }
                self.isTransitionGroupsLoaded = true;
                self.loadingData = false;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    ModuleN.prototype.new_TransitionGroup = function () {
        var self = this;
        var item = new TransitionGroupMod(null, self.workflowTransitionGroup.workflowId);
        return item;
    }
    ModuleN.prototype.transitionGroup_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting item..." : "Saving item...";
        var action = isDelete ? item.delete() : item.save();
        action
            .then(function () {
                if (isDelete) {
                    self.transitionGroupsList.splice(self.transitionGroupsList.indexOf(item), 1);
                }
                if (!item.properties.id) {
                    delete item.form;
                    self.get_transitionGroupsList();
                }

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    // signatures

    ModuleN.prototype.signatures_init = function () {
        var self = this;
        var p = $q.defer();
        self.get_signaturesDicts(self.workflowSignatures.workflowId)
            .then(function () {
                self.get_signaturesList();
            })
            .catch(function () {

            })

        return p.promise;
    }

    ModuleN.prototype.get_signaturesList = function () {
        var self = this;
        var p = $q.defer();
        self.signaturesList;
        self.isSignaturesLoaded = false;
        self.isBusy = true;
        var dataURL = URI.MODULE_EXTRA_SIGNATURES.SEARCH;
        self[dataURL.method](dataURL, { url: { workflowId: self.workflowSignatures.workflowId }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
            .then(function (result) {
                self.signaturesList = [];
                if (result && result.data && result.data.length) {
                    for (var i = 0; i < result.data.length; i++) {
                        var signature = new SignatureMod(self.properties.id, result.data[i]);
                        signature.rolesDict = self.signatureRolesDict;
                        Object.defineProperty(signature, 'statesDict', {
                            get: function () { return self.closedStatesDict; }
                        });
                        // signature.statesDict = self.closedStatesDict;
                        // transitionGroup.setDicts(self.relationTypesDict, self.modulesDict);
                        self.signaturesList.push(signature);
                    }
                }

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
                self.isSignaturesLoaded = true;
                self.loadingData = false;
            })

        return p.promise;
    }

    ModuleN.prototype.new_Signature = function () {
        var self = this;
        var item = new SignatureMod(self.properties.id);
        item.rolesDict = self.signatureRolesDict;
        item.statesDict = self.closedStatesDict;
        item.createForm();
        return item;
    }
    ModuleN.prototype.signature_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting item..." : "Saving item...";
        var action = isDelete ? item.delete() : item.save();
        action
            .then(function () {
                if (isDelete) {
                    self.signaturesList.splice(self.signaturesList.indexOf(item), 1);
                }
                else {
                    delete item.form;
                    self.newItem = null;
                }

                self.get_signaturesList();


                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    // workflow states
    ModuleN.prototype.new_State = function (stateType) {
        var self = this;
        var state = new StateMod({ type: stateType }, self.workflow.workflowId);
        state.properties.position = self.statesList.length + 1;
        return state;
    }
    ModuleN.prototype.states_init = function (includeDelete) {
        var self = this;
        ModuleDictionaries.addUrlParameter({ workflowId: self.workflow.workflowId });
        ModuleDictionaries.refreshDicts(["allRoles", "stateTypes", "signatures", "notifications", "states", "forms"])
            .then(function () { self.get_StatesList(includeDelete); })
    }

    ModuleN.prototype.sync_States = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.STATES.SYNC_ORDER;
        var list = [];
        for (var i = 0; i < self.statesList.length; i++) {
            list.push({ key: self.statesList[i].properties.id, value: self.statesList[i].properties.name, order: i });
        }
        self.isBusy = true;

        self[urlData.method](urlData, { body: list }, { headers: { moduleId: self.properties.id } })
            .then(function (result) {
                p.resolve();
                Message.info("Order updated.");
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
                Message.dberror('Order update failed');
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    ModuleN.prototype.setWorkflow = function (workflowId) {
        var self = this;
        self.workflow = new WorkflowMod(self.properties.id, workflowId);
        self.workflow.workflowGroupName = self.workflows.find(w => w.id == workflowId).workflowGroupName;
        self.isWorkflowLoaded = true;
    }

    ModuleN.prototype.getFirstWorkflow = function () {
        var self = this;

        if (self.workflows && self.workflows.length > 0) {
            // Get default workflow first, is case is not found, get first found
            var workflow = self.workflows.find(w => w.isDefault) ?? self.workflows[0];
            // Return id
            return workflow.id;
        }
        else {
            return 0;
        }
    }

    ModuleN.prototype.getWorkflows = function (workflowId) {
        var self = this;
        self.isWorkflowLoaded = false;
        var p = $q.defer();
        var dataURL = URI.WORKFLOW.ALL;
        self[dataURL.method](dataURL, {}, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                self.workflows = r.workflows;
                self.canAddWorkflow = r.canAddWorkflow;
                self.availableWorkflowGroups = r.availableWorkflowGroups;
                self.setWorkflow(workflowId ?? self.getFirstWorkflow());
                p.resolve();
            })
            .catch(function (e) { console.error(e); p.reject(); });

        return p.promise;
    }

    ModuleN.prototype.getWorkflowsTransitionGroups = function (workflowId) {
        var self = this;
        self.isWorkflowTransitionGroupLoaded = false;
        var p = $q.defer();
        var dataURL = URI.WORKFLOW.ALL;
        self[dataURL.method](dataURL, {}, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                self.workflows = r.workflows;
                workflowId = workflowId ?? self.getFirstWorkflow();
                self.workflowTransitionGroup = new WorkflowMod(self.properties.id, workflowId);
                self.workflowTransitionGroup.workflowGroupName = self.workflows.find(w => w.id == workflowId).workflowGroupName;
                self.isWorkflowTransitionGroupLoaded = true;
                p.resolve();
            })
            .catch(function (e) { console.error(e); p.reject(); });

        return p.promise;
    }

    ModuleN.prototype.getWorkflowsSignatures = function (workflowId) {
        var self = this;
        self.isWorkflowSignaturesLoaded = false;
        var p = $q.defer();
        var dataURL = URI.WORKFLOW.ALL;
        self[dataURL.method](dataURL, {}, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                self.workflows = r.workflows;
                workflowId = workflowId ?? self.getFirstWorkflow();
                self.workflowSignatures = new WorkflowMod(self.properties.id, workflowId);
                self.workflowSignatures.workflowGroupName = self.workflows.find(w => w.id == workflowId).workflowGroupName;
                self.isWorkflowSignaturesLoaded = true;
                p.resolve();
            })
            .catch(function (e) { console.error(e); p.reject(); });

        return p.promise;
    }

    ModuleN.prototype.addWorkflow = function (workflowGroup) {
        var self = this;
        var p = $q.defer();

        self.loadingData = true;

        // get start screens
        ModuleDictionaries.get_dataSource("startScreens")
            .then(function () {
                // Set loading screen back to false
                self.loadingData = false;

                // Define dialog local data object
                var dialogLocals = {
                    data: {
                        title: "Choose Screens",
                        buttonLabels: [
                            { key: 'confirm', value: 'Proceed' },
                            { key: 'cancel', value: 'Cancel' },
                        ],
                        options: ModuleDictionaries.dataSources.startScreens.data,
                        multiple: true
                    }
                }

                // Define dialog
                var dialog = $mdDialog
                    .confirm({
                        templateUrl: '/ng/views/system/modals/selectModal.tmpl.html',
                        locals: dialogLocals,
                        controller: 'selectDialogController',
                        parent: angular.element(document.body),
                        fullscreen: true,
                        focusOnOpen: false,
                        multiple: true,
                        escapeToClose: false,
                        clickOutsideToClose: false
                    });

                // Open dialog
                $mdDialog.show(dialog)
                    .then(function (screens) {

                        if (!screens || screens.length == 0)
                            return;

                        var dataURL = URI.WORKFLOW.ADD;
                        var bodyParams = {
                            workflowGroupId: workflowGroup.key,
                            screenIds: screens
                        };
                        self[dataURL.method](dataURL, { body: bodyParams }, { headers: { moduleId: self.properties.id } })
                            .then(function (r) {
                                Message.info('Workflow added successfully.');
                                self.refreshWorkflow(r);
                                p.resolve();
                            })
                            .catch(function (e) { console.error(e); p.reject(); });
                    })
                    .catch(function (e) { p.reject(); });
            })
            .catch(function () {
                self.loadingData = false;
                p.reject();
            })

        return p.promise;
    }

    ModuleN.prototype.removeWorkflow = function () {
        var self = this;
        $mdDialog.show(confirm('Remove Workflow', 'Are you sure you want to delete current workflow ?', true))
            .then(function () {
                self.loadingData = true;
                var dataURL = URI.WORKFLOW.DELETE;
                self[dataURL.method](dataURL, { url: { id: self.workflow.workflowId }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
                    .then(function () {
                        self.loadingData = false;
                        Message.info('Workflow deleted successfully.');
                        self.refreshWorkflow();
                    })
                    .catch(function (e) { console.error(e); self.loadingData = false; });
            });
    }

    ModuleN.prototype.getWorkflowByGroup = function (groupId) {
        var self = this;
        return self.workflows.find(w => w.workflowGroupId == groupId).id;
    }

    ModuleN.prototype.refreshWorkflow = function (workflowId) {
        var self = this;
        self.loadingData = true;
        self.getWorkflows(workflowId)
            .then(function () {
                self.states_init();
            });
    }

    ModuleN.prototype.refreshWorkflowTransitionGroup = function (workflowId) {
        var self = this;
        self.loadingData = true;
        self.getWorkflowsTransitionGroups(workflowId)
            .then(function () {
                self.transitionGroups_init();
            });
    }

    ModuleN.prototype.refreshWorkflowSignatures = function (workflowId) {
        var self = this;
        self.loadingData = true;
        self.getWorkflowsSignatures(workflowId)
            .then(function () {
                self.signatures_init();
            });
    }

    ModuleN.prototype.moveWorkflow = function (groupId) {
        var self = this;
        var groupName = self.availableWorkflowGroups.find(w => w.key == groupId).value;
        $mdDialog.show(confirm('Move Workflow', `Do you want to move current workflow to ${groupName} workflow group ?`, true))
            .then(function () {
                self.loadingData = true;
                var dataURL = URI.WORKFLOW.CHANGE_GROUP;
                self[dataURL.method](dataURL, { url: { id: self.workflow.workflowId, workflowGroupId: groupId }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
                    .then(function () {
                        self.loadingData = false;
                        Message.info('Workflow moved successfully.');
                        self.refreshWorkflow(self.workflow.workflowId);
                    })
                    .catch(function (e) { console.error(e); self.loadingData = false; });
            });
    }

    ModuleN.prototype.duplicateWorkflow = function (groupId) {
        var self = this;
        var groupName = self.availableWorkflowGroups.find(w => w.key == groupId).value;
        $mdDialog.show(confirm('Duplicate Workflow', `Do you want to duplicate current workflow into ${groupName} workflow group ?`, true))
            .then(function () {
                self.loadingData = true;
                var dataURL = URI.WORKFLOW.DUPLICATE;
                self[dataURL.method](dataURL, { url: { id: self.workflow.workflowId, workflowGroupId: groupId }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
                    .then(function (r) {
                        self.loadingData = false;
                        Message.info('Workflow duplicated successfully.');
                        self.refreshWorkflow(r);
                    })
                    .catch(function (e) { console.error(e); self.loadingData = false; });
            });
    }

    ModuleN.prototype.get_StatesList = function (includeDelete) {
        var self = this;
        var p = $q.defer();
        var urlData = URI.STATES.LIST;
        self.isStatesLoaded = false;
        self.isBusy = true;
        var draftState = new StateMod({ default: true, name: 'Draft' }, self.workflow.workflowId);
        var getList = function () {
            self[urlData.method](urlData, { url: { workflowId: self.workflow.workflowId, showDeleted: includeDelete }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
                .then(function (result) {
                    self.statesList = [draftState];
                    self.statesDict = [];
                    if (result && result.length) {
                        for (var i = 0; i < result.length; i++) {
                            self.statesDict.push({ key: result[i].id, value: result[i].name, type: result[i].type });
                        }
                        for (var i = 0; i < result.length; i++) {
                            var state = new StateMod(result[i], self.workflow.workflowId);
                            // preserve the transitions expanded status
                            if (self.lookupStates[result[i].id] && self.lookupStates[result[i].id].listExpanded)
                                state.listExpanded = self.lookupStates[result[i].id].listExpanded;

                            self.statesList.push(state);
                            self.lookupStates[result[i].id] = state;
                        }
                    }

                    var usedIDs = [];
                    self.statesList.forEach(function (s) {
                        [
                            s.properties.templateNotificationId, s.properties.needsToApproveTemplateId,
                            s.properties.hasApprovedTemplateId, s.properties.hasRejectedTemplateId
                        ]
                            .forEach(function (id) { if (id && usedIDs.indexOf(id) == -1) usedIDs.push(id); });
                    });

                    if (self.isNotificationsLoaded && self.usedTemplateIDs) {
                        var notIn = function (list) { return function (id) { return list.indexOf(id) == -1; } } //  list => id => list.indexOf(id) == -1;
                        var selectionChanged = usedIDs.some(notIn(self.usedTemplateIDs)) || self.usedTemplateIDs.some(notIn(usedIDs));

                        self.isNotificationsLoaded = !selectionChanged;
                    }

                    self.usedTemplateIDs = usedIDs;
                    self.isStatesLoaded = true;
                    self.loadingData = false;
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {
                    self.isBusy = false;
                })
        }

        ModuleDictionaries.get_dataSource("conditionalOperators")
            .then(function () {
                getList();

            })
        return p.promise;
    }

    ModuleN.prototype.state_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting state..." : "Saving state...";
        self.anyStateRemovedApproval ||= item.removedApproval;

        var action = isDelete ? item.delete() : item.save();
        action
            .then(function () {
                self.get_StatesList();
                self.get_signaturesDicts(self.workflow.workflowId);
                delete item.form;
                // reload dicts
                ModuleDictionaries.addUrlParameter({ workflowId: self.workflow.workflowId });
                ModuleDictionaries.get_dataSource("states");
                ModuleDictionaries.get_dataSource("closedStates");
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ModuleN.prototype.set_GlobalTransitions = function () {
        var self = this;
        self.isBusy = true;
        self.gState = new StateMod({ id: -1, type: 103, name: 'Current Workflow' }, self.workflow.workflowId);
        //Object.defineProperty(self.gState, 'assignedRolesDict', {
        //    get: function () { return self.assignedRolesDict; }
        //});
        //Object.defineProperty(self.gState, 'notificationsDict', {
        //    get: function () { return self.notificationsDict; }
        //});
        //Object.defineProperty(self.gState, 'screensDict', {
        //    get: function () { return self.formsDict; }
        //});
        //gState.setDicts(self.stateTypesDict, self.statesDict, self.assignedRolesDict, screensDict, self.notificationsDict, self.signatureDict);
        self.gState.setDicts(self.stateTypesDict, self.statesDict, self.assignedRolesDict, self.formsDict, self.notificationsDict);

        self.gState.get_globalTransitions()
            .then(function (r) {
                self.gState.isGTloaded = true;

            })
            .catch(function () {

            })
    }

    ModuleN.prototype.cancel_GT = function (type) {
        var self = this;
        self.isBusy = false;
        self.gState = null;
        self.isGStateClosed = true;
        self.get_StatesList();
    }

    // permissions related

    // roles

    ModuleN.prototype.openAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        if (!self.dirtyPermissions) self.isBusy = !self.isBusy;
        ModuleDictionaries.getDicts(["allRoles"])
            .then(function () {
                self.assignmentsDict = angular.copy(ModuleDictionaries.dataSources.allRoles.data);
            })
    }

    ModuleN.prototype.cancelAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        self.isBusy = !self.isBusy;
        // self.assignmentsDict = angular.copy(self.assigmentsDictBkp);
    }

    // select/deselect all permissions, globally or /role
    ModuleN.prototype.all_permissions = function (e, isSelectAll, role) {
        var self = this;
        var p = $q.defer();
        e.preventDefault();
        e.stopPropagation();
        if (!role) {
            for (var i = 0; i < self.rolesList.length; i++) {
                if ((self.rolesList[i].permissions || []).length) {
                    self.rolesList[i].permissionsLine = '';
                    for (var j = 0; j < self.rolesList[i].permissions.length; j++) {
                        if (self.rolesList[i].permissions[j].isDefault) {
                            continue;
                        }
                        if (isSelectAll) {
                            self.rolesList[i].permissions[j].isUsed = true;
                            self.rolesList[i].permissions[j].filterId = self.rolesList[i].permissions[j].filterId || self.rolesList[i].permissions[j].filters[0].key;

                        } else {
                            self.rolesList[i].permissions[j].isUsed = false;
                            self.rolesList[i].permissions[j].filterId = self.rolesList[i].permissions[j].filters[0].key;
                        }

                        self.rolesList[i].permissions[j].isDirty = self.checkPermissionDirty(self.rolesList[i].permissions[j], self.rolesList[i]);
                    }
                }
                self.rolesList[i].permissionsLine = (self.rolesList[i].permissions || []).map(p => p.isUsed ? p.name : '').filter(p => !!p).join(', ');
            }
        } else {
            if ((role.permissions || []).length) {
                role.permissionsLine = '';
                for (var j = 0; j < role.permissions.length; j++) {
                    if (role.permissions[j].isDefault) {
                        continue;
                    }
                    if (isSelectAll) {
                        role.permissions[j].isUsed = true;
                        role.permissions[j].filterId = role.permissions[j].filterId || role.permissions[j].filters[0].key;

                    } else {
                        role.permissions[j].isUsed = false;
                        role.permissions[j].filterId = role.permissions[j].filters[0].key;
                    }

                    role.permissions[j].isDirty = self.checkPermissionDirty(role.permissions[j], role);
                }
                role.permissionsLine = (role.permissions || []).map(p => p.isUsed ? p.name : '').filter(p => !!p).join(', ');
            }
        }

    }

    ModuleN.prototype.sync_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isSavingAssignments = true;
        var urlData = URI.ROLES.ASSIGN_ROLES_TO_MODULETYPE;
        var toSend = [];
        for (var i = 0; i < self.assignmentsDict.length; i++) {
            if (self.assignmentsDict[i].isUsed) toSend.push(self.assignmentsDict[i].key);
        }
        self[urlData.method](urlData, { url: {}, body: { id: self.currentType.key || self.properties.id, roleIds: toSend }, urltype: 'obj' })
            .then(function (r) {
                self.isShowingRoles = false;
                self.get_RolesList();
                ModuleDictionaries.get_dataSource("allRoles");
                Message.info('Assignments saved successfully.')
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isSavingAssignments = false;
                self.isBusy = false;
            });

        return p.promise;
    }

    ModuleN.prototype.save_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.ROLES.SYNC_MODULE_ROLES;
        var toSend = self.extractPermissions();
        self[urlData.method](urlData, { url: {}, urltype: 'obj', body: toSend })
            .then(function (r) {
                ModuleDictionaries.get_dataSource("allRoles");
                self.get_RolesList();
                self.restoreRolesList = angular.copy(self.rolesList);

                self.backupRolesList = angular.copy(self.extractPermissions());
                p.resolve(r);
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }


    ModuleN.prototype.roles_init = function (moduleType) {
        var self = this;

        self.currentType = moduleType || (self.currentType || self.moduleTypes[0]);
        if (self.isShowingRoles) self.isBusy = true;
        self.get_RolesList();
        ModuleDictionaries.getDicts(["allRoles"]);
    }

    ModuleN.prototype.processItem = function (item) {
        item.permissionsLine = "";
        if ((item.permissions || []).length) {
            for (var j = 0; j < item.permissions.length; j++) {
                item.permissions[j].filterId = item.permissions[j].filterId || item.permissions[j].filters[0].key;
                if (item.permissions[j].isUsed) item.permissionsLine += item.permissions[j].name + ', ';
            }
        }
        if (!item.permissionsLine) item.permissionsLine = '';
        else item.permissionsLine = item.permissionsLine.substring(0, item.permissionsLine.length - 2);

        if (!item.bkpPermissionsLine) item.bkpPermissionsLine = item.permissionsLine;
        return item;
    }

    ModuleN.prototype.extractPermissions = function () {
        var self = this;
        var permissionsObj = {
            id: self.currentType.key,
            roles: []
        };
        if (self.rolesList && self.rolesList.length) {
            for (var i = 0; i < self.rolesList.length; i++) {
                var role = { roleId: self.rolesList[i].roleId };
                role.permissions = [];
                if (self.rolesList[i].permissions && self.rolesList[i].permissions.length) {

                    for (var j = 0; j < self.rolesList[i].permissions.length; j++) {

                        if (self.rolesList[i].permissions[j].isUsed)
                            role.permissions.push({
                                permissionId: self.rolesList[i].permissions[j].id,
                                filterId: self.rolesList[i].permissions[j].filterId
                            });
                    }
                    if (role.permissions.length) permissionsObj.roles.push(role);
                }
            }
        }

        return self.rolesList.length ? permissionsObj : [];

    }

    ModuleN.prototype.checkPermissionDirty = function (permission, role) {
        var self = this;

        var isDirty = !self.permissionsLookup[role.roleId][permission.id] && permission.isUsed
            || self.permissionsLookup[role.roleId][permission.id] && !permission.isUsed
            || permission.isUsed && self.permissionsLookup[role.roleId][permission.id] != permission.filterId;

        return isDirty;
    }

    ModuleN.prototype.get_RolesList = function (moduleType) {
        var self = this;
        var p = $q.defer();
        // ModuleDictionaries.getDicts(["allRoles"]);
        self.isRolesLoaded = false;
        if (moduleType || self.rolesModuleTypeKey != self.currentType.key) self.rolesList = [];
        self.currentType = moduleType || (self.currentType || self.moduleTypes[0]);
        self.rolesModuleTypeKey = self.currentType.key;
        var dataURL = URI.ROLES.GET_MODULE_ROLES;
        self.permissionsLookup = {};
        self[dataURL.method](dataURL, { url: { moduleTypeId: self.currentType.key }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                // self.rolesList = r.data;
                if (r.data && r.data.length) {
                    self.set_permission_dirty(r.data);
                }

                self.rolesList = r.data || [];

                ModuleDictionaries.getDicts(["allRoles"])
                    .then(function () {
                        for (var i = 0; i < self.rolesList.length; i++) {
                            var lookupRole = ModuleDictionaries.dataSources.allRoles.lookup[self.rolesList[i].roleId];
                            if (lookupRole) {
                                self.rolesList[i].isDisabled = lookupRole.isDisabled || false;
                            }
                        }
                    })

                self.restoreRolesList = angular.copy(self.rolesList);
                self.backupRolesList = angular.copy(self.extractPermissions());

                p.resolve();
                self.isRolesLoaded = true;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                // self.isBusy = false;
            })

        return p.promise;
    }

    ModuleN.prototype.set_permission_dirty = function (data) {
        var self = this;

        for (var i = 0; i < data.length; i++) {
            data[i] = self.processItem(data[i]);
            data[i].isExpanded = (self.rolesLookup[data[i].roleId] || {}).isExpanded || false;
            self.permissionsLookup[data[i].roleId] = {};
            for (var j = 0; j < data[i].permissions.length; j++) {

                if (data[i].permissions[j].isUsed) {
                    self.permissionsLookup[data[i].roleId][data[i].permissions[j].id] = data[i].permissions[j].filterId;
                }
                data[i].permissions[j].isDirty = false;

            }

            (function (index) {
                Object.defineProperty(data[index], 'isDirty', {
                    get: function () {
                        var isDirty = false;
                        for (var k = 0; k < data[index].permissions.length; k++) {
                            if (data[index].permissions[k].isDirty) isDirty = true;
                            // break;
                        }

                        return isDirty;

                    }
                });
            }(i));

            self.rolesLookup[data[i].roleId] = data[i];



        }

    }

    // form
    ModuleN.prototype.new_FormConfig = function (type) {
        var self = this;
        var form = new FormConfig(
            {
                id: self.properties.id,
                currentType: self.currentType
            },
            {
                type: type,
                isStartScreen: type == ScreenTypeEnum.Entity,
                onFormSaved: function () {
                    self.mainScreenModified = true;
                }
            });
        form.createForm();
        return form;
    }

    ModuleN.prototype.formConfig_Action = function (form, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting form..." : "Saving form...";
        var action = isDelete ? form.delete() : form.save();
        action
            .then(function () {
                delete form.form;
                self.get_FormsList();
                if (form.properties.isStartScreen || form.properties.type == ScreenTypeEnum.Entity 
                    || form.properties.type == ScreenTypeEnum.Card || form.properties.type == ScreenTypeEnum.Calendar)
                    self.mainScreenModified = true;
                ModuleDictionaries.get_dataSource("forms");

                // refresh workflow if we add a new screen, when we create a new screen, we have screenId, but we don't have versionId
                if (isDelete || !isDelete && !self.properties.versionId) {
                    ModuleDictionaries.refreshDicts(["startScreens"])
                        .then(function () { self.getWorkflows(); })
                }

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ModuleN.prototype.get_FormsList = function () {
        var self = this;
        var p = $q.defer();
        self.loadingScreens = true;
        var urlData = URI.SCREEN_DEFINITION.SEARCH;
        self.isBusy = true;

        ModuleDictionaries.getDicts(["mimetypes", "dataSources", "selectsDataSources", "modules", "moduleEntities"]);

        var getList = function () {
            self[urlData.method](urlData, {}, { headers: { moduleId: self.properties.id } })
                .then(function (result) {
                    self.formsList = [];
                    self.fieldsList = [];
                    self.cardViewList = [];
                    self.calendarViewList = [];

                    if (result.data && result.data.length)
                        for (var i = 0; i < result.data.length; i++) {
                            var form = new FormConfig({
                                id: self.properties.id,
                                currentType: self.currentType
                            },
                                {
                                    ...result.data[i],
                                    onFormSaved: function () {
                                        self.mainScreenModified = true;
                                    }
                                });
                            if (result.data[i].type == ScreenTypeEnum.Entity || result.data[i].isStartScreen) self.fieldsList.push(form);
                            else if (result.data[i].type == ScreenTypeEnum.Action) self.formsList.push(form);
                            else if (result.data[i].type == ScreenTypeEnum.Card) self.cardViewList.push(form);
                            else if (result.data[i].type == ScreenTypeEnum.Calendar) self.calendarViewList.push(form);
                        }
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject();
                })
                .finally(function () {
                    self.loadingScreens = false;
                    self.isFormsLoaded = true;
                    self.isBusy = false;
                })
        }

        getList();

        return p.promise;
    }

    // screens
    // to be removed
    ModuleN.prototype.new_Screen = function (isStartScreen) {
        var self = this;
        return new Screen(self.properties.id, null, isStartScreen, self.fieldsDictSource);
    }

    ModuleN.prototype.get_FieldsDict = function () {
        var self = this;

        var p = $q.defer();
        Dictionaries.Fields({ moduleId: self.properties.id })
            .then(function (result) {
                self.fieldsDictSource = result;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isFieldsDictLoaded = true;
            })
        return p.promise;
    }

    ModuleN.prototype.screen_Action = function (screen, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting screen..." : "Saving screen...";
        var action = isDelete ? screen.delete() : screen.save();
        action
            .then(function () {
                delete screen.form;
                self.get_ScreensList();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ModuleN.prototype.get_ScreensList = function () {
        var self = this;
        var p = $q.defer();
        self.loadingScreens = true;
        var urlData = URI.SCREENS.SEARCH;
        self.isBusy = true;
        var getList = function () {
            self[urlData.method](urlData, {}, { headers: { moduleId: self.properties.id } })
                .then(function (result) {
                    self.screensList = [];
                    self.startscreensList = [];
                    var modulesDict = angular.copy(self.modulesDict);
                    if (self.modulesDict && self.modulesDict.length) {

                        for (var i = 0; i < modulesDict.length; i++) {
                            modulesDict[i].key = modulesDict[i].key + '|' + modulesDict[i].type;
                        }
                    }
                    if (result.data && result.data.length)
                        for (var i = 0; i < result.data.length; i++) {
                            var screen = new Screen(self.properties.id, result.data[i], result.data[i].isStartScreen, self.fieldsDictSource);
                            screen.modulesDict = modulesDict;
                            if (result.data[i].isStartScreen) self.startscreensList.push(screen);
                            else self.screensList.push(screen);
                        }
                    self.get_screensDict();
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject();
                })
                .finally(function () {
                    self.loadingScreens = false;
                    self.isScreensLoaded = true;
                    self.isBusy = false;
                })
        }

        if (self.isRelationDictsLoaded) {
            self.get_RelationsList();
        }

        if (self.isFieldsDictLoaded && self.isRelationDictsLoaded) {
            getList();
        } else {
            var toDo = [];
            if (!self.isFieldsDictLoaded) toDo.push(self.get_FieldsDict());
            if (!self.isRelationDictsLoaded) toDo.push(self.get_relationDicts());
            $q.all(toDo)
                .then(function () { getList(); })
                .catch(function () { });
        }


        return p.promise;

    }

    // templates
    ModuleN.prototype.new_Template = function (isNotification, screenId) {
        var self = this;
        var template = new TemplateMod(self.properties.id, isNotification, false, screenId);
        return template;
    }

    ModuleN.prototype.new_TemplateGroup = function (screenId) {
        var self = this;
        var template = new TemplateMod(self.properties.id, false, true, screenId);
        return template;
    }

    ModuleN.prototype.template_Action = function (template, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting template..." : "Saving template...";
        var action = isDelete ? template.delete() : template.save();
        action
            .then(function () {
                delete template.form;
                var isNotification = template.properties.typeName == TEMPLATES_TYPES[3] ? true : false;
                if (isNotification) ModuleDictionaries.get_dataSource("notifications");
                self.templatesChanged = !isNotification;
                self.get_TemplatesList(isNotification, template.properties.screenId);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ModuleN.prototype.templateGroup_Action = function (group, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting template group..." : "Saving template group...";

        var action = isDelete ? group.deleteGroup() : group.saveGroup();
        action
            .then(function () {
                delete group.form;
                self.templatesChanged = true;
                self.get_TemplatesList(false, group.properties.screenId);
                self.get_TemplatesGroupList(group.properties.screenId);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })

        return p.promise;
    }

    ModuleN.prototype.set_default_Template = function (template) {
        var self = this;
        self.isBusy = true;
        template.set_default()
            .then(function (r) {
                self.get_TemplatesList(false, template.properties.screenId);
                self.get_TemplatesGroupList(template.properties.screenId);
            })
            .catch(function () { })
            .finally(function () {
                self.isBusy = false;
            })
    }

    ModuleN.prototype.set_default_GroupTemplate = function (template) {
        var self = this;
        self.isBusy = true;
        template.set_GroupDefault()
            .then(function (r) {
                self.get_TemplatesList(false, template.properties.screenId);
                self.get_TemplatesGroupList(template.properties.screenId);
            })
            .catch(function () { })
            .finally(function () {
                self.isBusy = false;
            })
    }

    ModuleN.prototype.set_tag_Template = function (template) {
        var self = this;
        self.isBusy = true;
        template.set_tag()
            .then(function (r) {
                self.get_TemplatesList(true, template.properties.screenId);
            })
            .catch(function () { })
            .finally(function () {
                self.isBusy = false;
            })
    }

    ModuleN.prototype.unset_tag_Template = function (template) {
        var self = this;
        self.isBusy = true;
        template.unset_default()
            .then(function (r) {
                self.get_TemplatesList(true, template.properties.screenId);
            })
            .catch(function () { })
            .finally(function () {
                self.isBusy = false;
            })
    }

    ModuleN.prototype.get_TemplatesList = function (isNotification, screenId) {
        var self = this;
        var p = $q.defer();
        //if (!self.isAssignedRolesLoaded && !isNotification) self.get_assignedRolesDict();
        //if (!self.isHFDictLoaded && !isNotification) self.get_headersFootersDict();
        if (isNotification) {
            self.loadingNotifications = true;
            //self.notificationList = [];
        } else {
            self.loadingTemplates = true;
            ModuleDictionaries.getDicts(["allRoles", "headers", "footers"]);
        }
        self.isBusy = true;
        var url = { url: { screenId: screenId }, urltype: 'obj' };
        if (isNotification) {
            url.url.typeName = TEMPLATES_TYPES[3];
        }
        else if (self.properties.id == -11) {
            url.url.typeName = TEMPLATES_TYPES[2];
        } else {
            url.url.typeName = TEMPLATES_TYPES[1];
        }
        var urlData = URI.TEMPLATES.SEARCH;
        self[urlData.method](urlData, url, { headers: { moduleId: self.properties.id } })
            .then(function (result) {
                var dictToUpdate = [];
                if (result.data && result.data.length)
                    for (var i = 0; i < result.data.length; i++) {
                        var template = new TemplateMod(self.properties.id, isNotification, false, screenId, result.data[i]);
                        //template.headersDict = self.headersDict;
                        //template.footersDict = self.footersDict;
                        //Object.defineProperty(template, 'assignedRolesDict', {
                        //    get: function () { return self.assignedRolesDict; }
                        //});
                        dictToUpdate.push(template);

                    }
                if (isNotification) {
                    self.notificationList = dictToUpdate;
                    // self.get_notificationsDict();
                } else {
                    if (self.currentItem && self.currentItem.switchToMiddleAction)
                        self.currentItem.templatesList = dictToUpdate;
                    else
                        self.templatesList = dictToUpdate;
                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
                if (isNotification) {
                    self.isNotificationsLoaded = true;
                    self.loadingNotifications = false;
                } else {
                    self.isTemplatesLoaded = true;
                    self.loadingTemplates = false;
                }
            })


        return p.promise;

    }

    ModuleN.prototype.get_TemplatesGroupList = function (screenId) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;

        var urlData = URI.TEMPLATES.SEARCH_GROUP;
        self[urlData.method](urlData, { url: { screenId: screenId }, urltype: 'obj' }, { headers: { moduleId: self.properties.id } })
            .then(function (result) {
                var dictToUpdate = [];
                if (result.data && result.data.length) {
                    for (var i = 0; i < result.data.length; i++) {
                        var templateGroup = new TemplateMod(self.properties.id, false, true, screenId, result.data[i]);
                        dictToUpdate.push(templateGroup);
                    }
                }

                if (self.currentItem && self.currentItem.switchToMiddleAction)
                    self.currentItem.templatesGroupList = dictToUpdate;
                else
                    self.templatesGroupList = dictToUpdate;

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
                self.isTemplatesGroupLoaded = true;
            })

        return p.promise;
    }

    // data list
    ModuleN.prototype.get_DataListSettings = function (moduleId, relationId) {
        var self = this;
        self.dataListConfig = new DataListConfig(moduleId, relationId);
    }

    ModuleN.prototype.reloadModuleDate = function (r) {
        var self = this;

        if (!self.properties.id) {
            self.properties.id = r;
            self.init(true);
        } else {
            if (self.properties.information != self.form.originalData.information) {
                self.isReloadModuleInformation = true;
            }
            ModuleDictionaries.get_dataSource("availableDocNumbers");
        }

        self.form.set_Data(self.properties);
        self.form.store_Data(self.properties);
    }

    var confirmSave = function (title, text, isMultiple) {
        return $mdDialog.confirm({
            title: title,
            textContent: text,
            ariaLabel: 'Confirm Dialogue',
            ok: 'Proceed',
            cancel: 'Cancel',
            multiple: isMultiple || false
        });
    }

    ModuleN.prototype.save = function () {
        var self = this;
        var p = $q.defer();

        self.form.validate();

        // If we changed suffix or prefix for an existing project folder id, then we pop a message
        if (self.properties.id && self.properties.projectFolderId && (self.properties.prefixFieldId != self.form.originalData.prefixFieldId || self.properties.suffixFieldId != self.form.originalData.suffixFieldId)) {

            $mdDialog.show(confirmSave('Change Prefix / Suffix', `This will rename existing folders with the new prefix / suffix. Do you want to continue ?`, true))
                .then(function () {
                    self.saveModule();
                    p.resolve();
                })
                .catch(function (e) {
                    p.resolve();
                });
        }
        else {

            self.saveModule();
            p.resolve();
        }

        return p.promise;
    }

    ModuleN.prototype.saveModule = function () {

        var self = this;
        var p = $q.defer();
        var urlData = self.properties.id ? URI.MODULES.EDIT : URI.MODULES.ADD;
        if (self.form.isValid) {
            self.form.loading = true;
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: {
                    ...self.properties,
                    iconId: self.properties.iconId || undefined,
                }
            };
            self[urlData.method](urlData, params)
                .then(function (r) {

                    // Reload data
                    self.reloadModuleDate(r);

                    Message.info('Module saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                });
        }
        else {
            p.reject();
        }

        return p.promise;
    }

    ModuleN.prototype.enable = function () {
        var self = this;
        var p = $q.defer();
        self[URI.MODULES.ENABLE.method](URI.MODULES.ENABLE, { body: { list: [self.properties.id] } })
            .then(function (result) {
                self.properties.disabled = false;
                self.form.storedData.disabled = false;
                self.form.originalData.disabled = false;
                // self.form.set_Data(self.properties);
                Message.info('Module activated successfully');
                p.resolve(result);
            })
            .catch(function (e) {
                self.disabled = true;
                Message.dberror(e);
                p.reject(e);
            })
        return p.promise;
    }

    ModuleN.prototype.disable = function () {
        var self = this;
        var p = $q.defer();
        self[URI.MODULES.DISABLE.method](URI.MODULES.DISABLE, { body: { list: [self.properties.id] } })
            .then(function (result) {
                self.properties.disabled = true;
                self.form.storedData.disabled = true;
                self.form.originalData.disabled = true;
                Message.info('Module deactivated successfully');
                p.resolve(result);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                self.disabled = false;
                p.reject(e);
            })
        return p.promise;
    }



    return ModuleN;
});
