import { cipo } from "cipo";
cipo.factory("angularJsService", [
	"userService",
	"$mdDialog",
	"$state",
	"Dictionaries",
	"jsToNg",
	function (userService, $mdDialog, $state, Dictionaries, jsToNg) {
		var service = {};

		service.goToVoid = function () {
			$state.transitionTo(
				"void",
				{},
				{
					notify: false, // No state change events
					location: false, // Do not change URL
				},
			);
		};

		service.setContextTree = function (contextTree) {
			userService.setContextTree(contextTree);
		};

		service.editContract = function (item, ev) {
			if (!userService.system.userdata.id) {
				userService.User()
					.then(function() {
						service.editContractInternal(item, ev);
					})
			} else {
				service.editContractInternal(item, ev);
			}
		}

		service.editContractInternal = function (item, ev) {
			var item = { ...userService.system.context.contract };
			item.isInitiator = false;
			$mdDialog
				.show({
					locals: {
						item: item,
						module: userService.system.modules["CT"],
						screenId: null,
					},
					controller: "editHContractController",
					templateUrl: "/ng/views/admin/modals/editHContract.html",
					parent: angular.element(document.body),
					targetEvent: ev,
					fullscreen: true,
					escapeToClose: false,
					clickOutsideToClose: false,
					disableParentScroll: true,
					multiple: true,
				})
				.then(
					function (result) {
						userService.isCurrentContractPeriodsChanged = true;
						userService.isReloadGrid = true;
					},
					function () {
						// Get workflow from current module
						if (userService.system.oldWorkflowId)
							userService.system.workflowId = userService.system.oldWorkflowId;
					},
				)
				.finally(function () {
					jsToNg.dialogClosed(item);
				});
		};

		service.changeContext = function (context, addLastUsedContract) {
			userService
				.setContext(context, addLastUsedContract)
				.then(userService.triggerMenuRefresh)
				.then(function () {
					if ($state.current.code == "DYN-MOD") {
						var params = angular.copy($state.params);
						var module =
							userService.getModuleObjectById($state.params.id) || {};

						params.contractId = module.perContract ? context.contract.id : 0;
						$state.transitionTo($state.current.name, params, {
							reload: false,
							inherit: true,
							notify: true,
						});
					} else if ($state.current.code == "DYN-PP") {
						var params = angular.copy($state.params);
						params.contractId = context.contract.id;
						params.periodId = 0;
						params.entityInstanceId = 0;
						$state.transitionTo($state.current.name, params, {
							reload: false,
							inherit: true,
							notify: true,
						});
					} else if ($state.current.code == "DYN-SOV") {
						var params = angular.copy($state.params);
						params.contractId = context.contract.id;
						params.entityInstanceId = 0;
						$state.transitionTo($state.current.name, params, {
							reload: false,
							inherit: true,
							notify: true,
						});
					} else if ($state.current.code == "DYN-MREP") {
						var params = angular.copy($state.params);
						var module =
							userService.getModuleObjectById($state.params.id) || {};

						params.contractId = module.perContract ? context.contract.id : 0;
						$state.transitionTo($state.current.name, params, {
							reload: true,
							inherit: true,
							notify: true,
						});
					}
				});
		};

		service.openDocument = function (item) {
			if (!userService.system.userdata.id) {
				userService.User()
					.then(function() {
						service.openDocumentInternal(item);
					})
			} else {
				service.openDocumentInternal(item);
			}
		}

		service.openDocumentInternal = function (item) {
			var contractId =
				typeof item != "undefined" ? parseInt(item.contractId) : 0;
			var itemToSend = {
				itemId:
					typeof item != "undefined" ? parseInt(item.entityInstanceId) : 0,
				fromItem: 0,
			};

			$mdDialog
				.show({
					locals: {
						item: itemToSend,
						module: {
							moduleId: item.moduleId,
							code: item.moduleCode,
							name: item.moduleName,
							perContract: !!item.contractId,
						},
						contractId: contractId,
						smallerFullscreen: false,
						screenId: null,
					},
					controller: "SaveWorkflowEntityInstanceController",
					templateUrl:
						"/ng/views/dynamics/modals/saveWorkflowEntityInstance.html",
					parent: angular.element(document.body),
					fullscreen: true,
					escapeToClose: false,
					clickOutsideToClose: false,
				})
				.finally(function () {
					jsToNg.dialogClosed(item.entityInstanceId);
				});
		};

		service.openModuleSettings = function (module) {
			if (!userService.system.userdata.id) {
				userService.User()
					.then(function() {
						service.openModuleSettingsInternal(module);
					})
			} else {
				service.openModuleSettingsInternal(module);
			}
		}

		service.openModuleSettingsInternal = function (module) {
			var obj = module;
			var moduleOperations = userService.getOperationsFor("M");
			obj.isConfig = true;
			var moduleObj = {
				abbreviation: obj.code,
				disabled: false,
				iconId: obj.iconId,
				id: obj.moduleId,
				isSystem: obj.typeId == 3 ? true : false,
				name: obj.name,
				perContract: obj.perContract,
				color: obj.color,
			};
			$mdDialog
				.show({
					locals: {
						obj: moduleObj,
						simplifiedVersion: false,
						operations: moduleOperations,
						module: obj,
					},
					controller: "editNModuleController",
					templateUrl: "/ng/views/admin/modals/editNModule.html",
					parent: angular.element(document.body),
					targetEvent: null,
					fullscreen: true,
					focusOnOpen: false,
					escapeToClose: false,
					multiple: true,
					clickOutsideToClose: false,
					hasBackdrop: false,
				})
				.then(
					function (result) {
						obj.code = result.abbr;
						userService
							.triggerMenuRefresh()
							.then(function () {
								$document[0].title = "CIPO® Software - " + module.name;
							})
							.catch(function () {});
						userService.isReloadGrid = result.isReloadGrid;
						userService.isReloadModuleInformation =
							result.isReloadModuleInformation || false;
						Dictionaries.RoleUsers({
							id: obj.moduleId,
							permissionId: 1,
							contractId: userService.system.userdata.contractId,
						})
							.then(function (r) {
								obj.roles = r;
							})
							.then(function () {});
					},
					function (isReloadGrid) {
						userService
							.triggerMenuRefresh()
							.then(function () {
								userService.isReloadGrid = isReloadGrid;
								Dictionaries.RoleUsers({
									id: obj.moduleId,
									permissionId: 1,
									contractId: userService.system.userdata.contractId,
								})
									.then(function (r) {
										obj.roles = r;
									})
									.then(function () {});
							})
							.catch(function () {});
					},
				)
				.finally(function () {
					jsToNg.dialogClosed(module.id);
				});
		};

		return service;
	},
]);
