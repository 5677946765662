import { cipo } from "cipo";
import moment from "moment-timezone";

var tenantController = function ($scope, $timeout, userService) {
    $scope.openMenu = function ($mdMenu, ev) {
        $mdMenu.open(ev);
        $timeout(function () {
            $scope.focusSearch = true;
            $timeout(function () {
                $scope.focusSearch = false;
            }, 300);
        }, 300);
    };
    
    var zone = userService.getZoneName();
    if (zone) {
        // setting moment timezone
        moment.tz.setDefault(zone);
    }
};

cipo.controller("tenantController", tenantController);
