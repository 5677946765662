import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { FolderDialogComponent } from './folder-dialog/folder-dialog.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { FileDialogComponent } from './file-dialog/file-dialog.component';
import { AttachmentsNoteComponent } from './attachments-note/attachments-note/attachments-note.component';

export const Ng1Files = angular
  .module('ng1module.files', [])
  .directive('appFolderDialog', downgradeComponent({ component: FolderDialogComponent }) as angular.IDirectiveFactory)
  .directive('appFileDialog', downgradeComponent({ component: FileDialogComponent }) as angular.IDirectiveFactory)
  .directive('appPermissions', downgradeComponent({ component: PermissionsComponent }) as angular.IDirectiveFactory)
  .directive('cipoAttachmentsNote', downgradeComponent({ component: AttachmentsNoteComponent }) as angular.IDirectiveFactory)
  .name;