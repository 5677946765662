import { cipo } from 'cipo';

cipo.directive("wfentityinstance", function ($timeout, $location, userService, $state, Message) {
    return {
        restrict: "E",
        scope: {
            "entityinstance": "=",
            "percontract": "=",
            "currency": "=?",
        },
        templateUrl: "/ng/views/directives/dynamics/wfentityinstance.html",
        controller: function ($scope, $http, URI, $mdDialog) {
            
            if (!$scope.entityinstance.isDraft) {
                $scope.collapsedDetails = false;
            }

            $scope.isProgramOrProject = $scope.entityinstance.moduleId == -10 || $scope.entityinstance.moduleId == -23;

            $scope.startTransition = function (t, r) {

                //special case to deal with document archiving actions
                if (t.name == 'Export Data') {
                    $scope.createArchive();
                    return;
                } 

                if (t.name == 'View Data Exports') {
                    $scope.viewArchives();
                    return;
                }

                if (t.name == 'Close Contract') {
                    $scope.closeContract();
                    return;
                } 

                if (t.name == 'Reopen Contract') {
                    $scope.reopenContract();
                    return;
                } 

                if (t.name == 'Archive Contract') {
                    $scope.archiveContract();
                    return;
                } 

                $scope.entityinstance.startTransitionWithDelay(t, r, 
                    function (autoTrigger) {
                        if (autoTrigger) $scope.triggerTransition();
                    }, 
                    function () {
                        if ($scope.$root && $scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
                            $scope.$apply();
                        }
                    }
                );
            }

            $scope.triggerTransition = function () {
                $scope.entityinstance.triggerTransitionWithDelay(function (result) {
                    if (result && $state.current.name == 'tenant.dm.details') {
                        var params = {
                            id: $scope.entityinstance.moduleId,
                            contractId: userService.system.userdata.contractId,
                            urlActionId: 0,
                            urlActionEntityId: 0,
                            entityId: result
                        };

                        try {
                            $state.transitionTo($state.current.name, params, {
                                reload: true,
                                inherit: true,
                                notify: true
                            });
                        }
                        catch (e) {
                            console.error(e);
                        }
                    } else {
                        if (result) {
                            $scope.entityinstance.entityInstanceId = result;
                            $scope.entityinstance.isModified = true
                            $scope.entityinstance.presetId = null;
                            $scope.entityinstance.presetProperties = {};                                
                        }
                        $scope.entityinstance.init();
                    }
                });
            }

            $scope.createArchive = function () {
                var filter = {};
                var modulesCheckboxes = [];
                var cNo;

                $http[URI.CT.CONTRACT_DETAILS.method](URI.CT.CONTRACT_DETAILS.toString() + "?entityInstanceId=" + $scope.entityinstance.entityInstanceId)
                    .then(function (result) {
                        if (result.data) {
                            cNo = result.data.contractNo;
                        }

                        $http[URI.MODULES.SEARCH.method](URI.MODULES.SEARCH.toString(), filter)
                            .then(function (result) {
                                if (result.data && result.data.data.length > 0) {
                                    for (var i = 0; i < result.data.data.length; i++) {
                                        if (result.data.data[i].perContract == true) {
                                            var desc = '(' + result.data.data[i].abbreviation + ')';
                                            if (!(result.data.data[i].projectFolderId != null)) {
                                                desc = desc + ' - This module has no Destination Folder defined!';
                                            }
                                            modulesCheckboxes.push({
                                                description: desc,
                                                abbr: result.data.data[i].abbreviation,
                                                key: result.data.data[i].id,
                                                value: result.data.data[i].name,
                                                label: result.data.data[i].name,
                                                folderId: result.data.data[i].projectFolderId,
                                                hasFolder: result.data.data[i].projectFolderId != null
                                            });
                                        }
                                    }
                                }

                                var dialogLocals = {
                                    data: {
                                        title: "Select the modules you would like to export data from:",
                                        description: " ",
                                        showSelectUnselect: true,
                                        buttonLabels: [
                                            { key: 'confirm', value: 'Export Data' },
                                            { key: 'cancel', value: 'Cancel' },
                                        ],
                                        entityInstanceId: $scope.entityinstance.entityInstanceId,
                                        contractNumber: cNo ?? '',
                                        checkboxes: modulesCheckboxes
                                    }
                                }

                                $mdDialog.show({
                                    templateUrl: '/ng/views/admin/modals/archiveModuleSelector.html',
                                    locals: dialogLocals,
                                    controller: 'archiveModuleSelectorController',
                                    parent: angular.element(document.body)
                                })
                                .then(function (result) {

                                }, function () {

                                });
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                            })                    
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                            })                           
            };

            $scope.viewArchives = function () {
                var tenantName = userService.system.tenantsLookup[userService.tenantId].name.toLowerCase();
                var url = tenantName + "/filecontractmanager";

                $mdDialog.cancel();
                $location.url(url);                                  
            };

            var confirm = function (title, text, isMultiple) {
                return $mdDialog.confirm({
                    title: title,
                    textContent: text,
                    ariaLabel: 'Confirm Dialogue',
                    ok: 'Continue',
                    cancel: 'Cancel',
                    multiple: isMultiple || false
                });
            }

            $scope.archiveContract = function () {
                $mdDialog.show(confirm('Archive Contract', `You are about to archive [${$scope.entityinstance.generalInfo.contractNo} - ${$scope.entityinstance.generalInfo.contractName}]. By performing this action, all documents under this contract will become read-only. This process is irreversible. Do you want to proceed?`, true))
                    .then(function () {
                        $http[URI.CT.ARCHIVE_CONTRACT.method](URI.CT.ARCHIVE_CONTRACT.toString() + "?entityInstanceId=" + $scope.entityinstance.entityInstanceId)
                        .then(function (result) {
                            userService.closeContract(3, $scope.entityinstance.entityInstanceId);
                            var projectFilesModuleName = userService.system.modules['FCM']?.name || 'Project Files';
                            Message.stickyWarning(`Contract documents archived successfully. The process of archiving documents in ${projectFilesModuleName} is running in the background and will take a few minutes.`);
                            $scope.entityinstance.init();

                            // mark the instance as modified so that when the contract dialog 
                            // can notify the opener that the contract has been modified
                            // i.e. the project files will reload the folder tree when the contract is modified
                            $scope.entityinstance.isModified = true;
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                        });           
                    });
            }

            $scope.closeContract = function () {
                $mdDialog.show(confirm('Close Contract', `You are about to close [${$scope.entityinstance.generalInfo.contractNo} - ${$scope.entityinstance.generalInfo.contractName}]. By performing this action, all documents under this contract will become read-only. There are currently ${$scope.entityinstance.generalInfo.openDocumentsCount} open documents. Do you want to proceed?`, true))
                    .then(function () {
                        $http[URI.CT.CLOSE_CONTRACT.method](URI.CT.CLOSE_CONTRACT.toString() + "?entityInstanceId=" + $scope.entityinstance.entityInstanceId)
                        .then(function (result) {
                            userService.closeContract(1, $scope.entityinstance.entityInstanceId);
                            Message.stickyWarning(`Contract documents closed successfully.`);
                            $scope.entityinstance.init();

                            // mark the instance as modified so that when the contract dialog 
                            // can notify the opener that the contract has been modified
                            // i.e. the project files will reload the folder tree when the contract is modified
                            $scope.entityinstance.isModified = true;
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                        });           
                    });
            }

            $scope.reopenContract = function () {
                $mdDialog.show(confirm('Reopen Contract', `You are about to reopen [${$scope.entityinstance.generalInfo.contractNo} - ${$scope.entityinstance.generalInfo.contractName}]. By performing this action, all open documents under this contract will become active again. ${$scope.entityinstance.generalInfo.openDocumentsCount} documents will be reopened. Do you want to proceed?`, true))
                    .then(function () {
                        $http[URI.CT.REOPEN_CONTRACT.method](URI.CT.REOPEN_CONTRACT.toString() + "?entityInstanceId=" + $scope.entityinstance.entityInstanceId)
                        .then(function (result) {
                            userService.closeContract(2, $scope.entityinstance.entityInstanceId);
                            Message.stickyWarning(`Contract documents reopened successfully.`);
                            $scope.entityinstance.init();

                            // mark the instance as modified so that when the contract dialog 
                            // can notify the opener that the contract has been modified
                            // i.e. the project files will reload the folder tree when the contract is modified
                            $scope.entityinstance.isModified = true;
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                        });           
                    });
            }

            // $scope.list = $scope.options.listKey ? $scope.parent[$scope.options.listKey] : [];
            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor($scope.entityinstance.moduleCode) }
            });
            Object.defineProperty($scope, 'nonBindingSignature', {
                get: function () {
                    return (userService.system.userdata.signature || {}).signatureText }
            });
            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $timeout(function () {
                    $scope.focusSearch = true;
                    $timeout(function () {
                        $scope.focusSearch = false;

                    }, 300);
                }, 300);
                $mdMenu.open(ev);
            };

            $scope.$watch(function () {
                return userService.system.userdata.signature;
            },
            function (n, o) {
                // if we didn't have a signature, but now we have
                if (n && !o && !$scope.entityinstance.transition.screen.form && !$scope.entityinstance.transition.screen.notificationForm) {
                    $scope.triggerTransition();
                }
            });
        }
    }
});