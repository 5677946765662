import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { AUTH_TOKEN, MODULE, TENANT } from '../consts';
import { MsalAuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authService = inject(MsalAuthService);
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        tenantId: this.getTenantId(),
        moduleId: this.getModuleId(request.headers),
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return throwError(() => error);
      }),
    );
  }

  private getToken(): string {
    return localStorage.getItem(AUTH_TOKEN) || '';
  }

  private getTenantId(): string {
    // this will ensure that each request from demo will have an tenantId. We need this until we'll implement Angular Routing
    const defaultTenant = window.location.href.includes('/demo') ? '417' : '';
    return sessionStorage.getItem(TENANT) || defaultTenant;
  }

  private getModuleId(headers: HttpHeaders): string {
    if (headers.has('moduleId')) {
      return headers.get('moduleId');
    }
    return sessionStorage.getItem(MODULE) || '';
  }
}
